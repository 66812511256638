
import { Component, Vue, Mixins, Prop } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD, OWNER_TYPE } from '@/mixin/VueHoduCommon';
import { GroupModalInfo, GroupListConfirmModalData, GroupTeamOtherOutModalInfo, GroupTeamOtherOutModalData, CommonInputModalInfo, GroupMemberImportModalInfo } from '@/store/modules/ModalInfo';
import GroupListConfirmModal from '@/components/GroupListConfirmModal.vue';

import { namespace } from 'vuex-class';
const CalendarInfo = namespace('CalendarInfo');
const GroupInfo    = namespace('GroupInfo');
const ModalInfo    = namespace('ModalInfo');

import GroupMemberComp from '@/components/GroupMemberComp.vue';

import { hodu_home_modal_info } from '@/model/hoduhome';

declare let Kakao : any;
import '@/assets/js/kakao';

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components : {
        GroupMemberComp, GroupListConfirmModal, ResizeObserver
    }
})
export default class GroupMember extends Mixins(VueHoduCommon) {

    event_bus : Vue = new Vue();

    /** 
     * CalendarInfo.Action 
     */ 
    @CalendarInfo.Action doSetStartDate ?: any; // start_date 업데이트
    
    /**
     * GroupInfo.State
     */
    @GroupInfo.State group_id !: number;
    @GroupInfo.State team_id !: number;
    @ModalInfo.State group_modal_info  !: GroupModalInfo;
    @ModalInfo.State group_team_other_out_info !: GroupTeamOtherOutModalInfo;

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupModalInfo ?: any;
    @ModalInfo.Action doSetGroupTeamOtherOutModalInfo ?: any;
    @ModalInfo.Action doSetHomeResidentCarRequestModalInfo ?: (params : hodu_home_modal_info.HomeResidentCarRequestModalInfo) => void;
    @ModalInfo.Action doSetGroupMemberImportModalInfo ?: (params : GroupMemberImportModalInfo) => void;

    /**
     * computed
     */
    get computedGroupMember() : any[] {
        const member_array : any[] = JSON.parse(JSON.stringify(this.member_data));

        if( this.member_sort_target != null ) {
            member_array.sort((o1 : any, o2 : any) : number => {
                // 이름 오름차순
                if( this.member_sort_target == "NAME" && this.member_sort_direction == "ASC" ) {

                    if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {

                    }

                    else {
                        if( o1.user_info.user_name  > o2.user_info.user_name ) { return  1; } 
                        if( o1.user_info.user_name  < o2.user_info.user_name ) { return -1; } 
                        if( o1.user_info.user_name == o2.user_info.user_name ) { return  0; }
                    }
                    
                }

                // 이름 내림차순
                if( this.member_sort_target == "NAME" && this.member_sort_direction == "DESC" ) {

                    if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {

                    }

                    else {
                        if( o1.user_info.user_name  > o2.user_info.user_name ) { return -1; } 
                        if( o1.user_info.user_name  < o2.user_info.user_name ) { return  1; } 
                        if( o1.user_info.user_name == o2.user_info.user_name ) { return  0; }
                    }
                }

                // 권한 오름차순
                if( this.member_sort_target == "ROLE" && this.member_sort_direction == "ASC" ) {
                    if( o1.role_level  > o2.role_level ) { return -1; }
                    if( o1.role_level  < o2.role_level ) { return  1; }
                    if( o1.role_level == o2.role_level ) { return  0; }
                }

                // 권한 내림차순
                if( this.member_sort_target == "ROLE" && this.member_sort_direction == "DESC" ) {
                    if( o1.role_level  > o2.role_level ) { return  1; }
                    if( o1.role_level  < o2.role_level ) { return -1; }
                    if( o1.role_level == o2.role_level ) { return  0; }
                }

                return 0;
            });
        }

        if( this.member_search_query != null && this.member_search_query.trim().length > 0 ) {
            return member_array.filter(member => this.hodu_string_includes(member.user_info.user_name, this.member_search_query) ||
                                                 this.hodu_string_includes(member.user_info.user_phone_number, this.member_search_query) ||
                                                 this.hodu_string_includes(member.user_info.user_email, this.member_search_query) );
        }

        return member_array;
    }

    /**
     * 변수 선언부
     */
    user_profile_no_image = require("@/assets/images/contents/im_photoB.gif");
    b_adminTeamModify   : boolean  = false;
    b_isMemberModify    : boolean  = false;
    b_isAuthChange      : boolean  = false;    // 권한설정 버튼
    b_isInvite          : boolean  = false;
    b_openInvite        : boolean  = false;
    member_data         : any      = [];
    appr_cnt            : number   = 0;
    scrollHeight        : number   = 0;
    member_search_query : string   = "";       // 멤버 검색 쿼리

    member_sort_target    : string | null = null; // NAME, ROLE
    member_sort_direction : string = "ASC";       // ASC, DESC

    open_invite_menu : boolean = false;
    kakao_share_init : boolean = false;

    async beforeMount() : Promise<void> {
        if ( this.group_id == 0 ) {
            this.movePrevPage();
            return;
        }
    }

    async mounted() {
        const vue = this;

        // 검색 버튼 클릭시 searchDiv 띄우기
        $("#section_ce .schBtn").click(function(){
            $("#section_ce .searchDiv").addClass("on");
            vue.$nextTick(() => $('#group_member_search').focus());
        });

        // 검색 닫기 버튼 클릭시 searchDiv 없애기
        $("#section_ce .searchDiv .closeBtn").click(function(){
            $("#section_ce .searchDiv").removeClass("on");
            $("#group_member_search").val("");
            vue.member_search_query = "";
            $("#group_member_search").trigger('keyup');
        });

        // 숫자만 입력가능하도록
        $("#modalMemRegPh").on("keyup", function() {
            // @ts-ignore
            $(this).val($(this).val().replace(/[^0-9]/g,""));
        });

        this.setScroll();
        
		// 멤버추가창 인풋에 wrong 빼기
		$(".inviteMateDiv input").click(function(){
			$(this).removeClass("wrong");
        });
        
		// 멤버추가창 다른 멤버 초대
		$(".inviteMateDiv .btns .inviteMore").click(function(){
            $('#modalMemRegNm').val("");
            $('#modalMemRegPh').val("");
            $(".inviteMateDiv").removeClass("sent");
        });

        $(".inviteMateDiv .btns .close").click(function(){
            $('#modalMemRegNm').val("");
            $('#modalMemRegPh').val("");
            $(".inviteMateDiv").removeClass("sent");
			vue.b_openInvite = false;
        });
        
        // 필터검색
        // $("#group_member_search").keyup(function(event) {
        //     let value = $("#group_member_search").val();
            
        //     if ( value == "" ) {  
        //         $("#group_member_ul li").show();
        //     }
        //     else {
        //         $("#group_member_ul li").hide();
        //         let temp = $("#group_member_ul li div .userName:contains('"+value+"')");
        //         $(temp).parent().parent().show();
        //     }
        // });

        await this.get_group_role_service();

        if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
            await this.residentSearch();
        }

        else {
            await this.getGroupMember();
        }

        window['residentSearch'] = this.residentSearch;
        
        window['group_member_reload'] = async() => {
            if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
                await vue.residentSearch();
            }

            else {
                await vue.getGroupMember();
            }
        };
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {
        const titleHeight : number | undefined = $('.title_box').outerHeight();
        const settingBgHeight : number | undefined = $('.settingBg').outerHeight();
        const headerHeight : number | undefined = $('#group_member_header').outerHeight();

        this.scrollHeight = window.innerHeight - ( titleHeight == null ? 0 : titleHeight) - ( settingBgHeight == null ? 0 : settingBgHeight) - ( headerHeight == null ? 0 : headerHeight);

        // @ts-ignore
        $('#group_member_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            setHeight : this.scrollHeight
        })
    }

    /**
     * 그룹멤버조회
     */
    async getGroupMember() : Promise<void> {
        this.member_data = [];
        this.appr_cnt    = 0;

        await this.hodu_api_call(`api/v1/groupsweb/getGroupTeamMember/${this.group_id}/${this.team_id}`, API_METHOD.GET, null)
            .then(async(response) => {
                this.b_adminTeamModify = await this.is_group_permmision(this.group_id, "team", "modify");
                this.b_isMemberModify  = await this.is_permmisionCheck(this.group_id, this.team_id, "member", "modify");
                this.b_isAuthChange    = false;
                
                this.b_isInvite        = await this.is_permmisionCheck(this.group_id, this.team_id, "member", "invite");

                // 호두 D는 일단 임시로 멤버 초대 막음
                if( this.isHoduD(this.scope_group_team_option ? this.scope_group_team_option.biz_type : '') == true ) { this.b_isInvite = false; }

                this.member_data       = response.data.data.list;
                this.appr_cnt          = response.data.data.appr_cnt;

                // 내 권한이 관리자 인지 부관리자 인지 알기위함...
                // 권한설정 버튼 보임 유무 때문에...
                for ( let i = (this.member_data.length - 1); i >= 0; i-- ) {

                    const member : any = JSON.parse(JSON.stringify(this.member_data[i]));

                    // 호두D는 그룹 멤버에 일반 멤버(환자)를 포함시키지 않는다
                    if( this.scope_group_team_option.biz_type == 'BIZD' && member.role == this.GroupAuth.GROUP_USER ) {
                        this.member_data.splice(i, 1);
                        continue;
                    }

                    // dropdown on / off 클래스 
                    member.member_role_on = false;

                    if ( member.user_id == this.user_id ) {
                        member.role_level = ( member.role == this.GroupAuth.GROUP_MANAGER     || member.role == this.GroupAuth.TEAM_MANAGER) ? 1 : 
                                              member.role == this.GroupAuth.GROUP_SUB_MANAGER || member.role == this.GroupAuth.TEAM_USER     ? 2 : 3;
                        this.b_isAuthChange = (this.team_id == 0 ? (member.role == this.GroupAuth.GROUP_MANAGER || member.role == this.GroupAuth.ADMIN) : this.b_adminTeamModify ? true : (member.role == this.GroupAuth.TEAM_MANAGER || member.role == this.GroupAuth.ADMIN));

                        // 호두 D는 권한 설정 불가
                        if( this.isHoduD(this.scope_group_team_option ? this.scope_group_team_option.biz_type : '') == true ) { this.b_isAuthChange = false; }
                    }
                    
                    
                    this.member_data.splice(i, 1, member);
                }

                this.$nextTick(() => this.kakaoShareInit());
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 입주자 검색
     */
    residentSearch() : void {

        this.hodu_api_call(`api/v1/home/resident/${this.scope_group_team_option.biz_id}?team_id=${this.team_id}`, API_METHOD.GET)
            .then(async(response) => {
                console.log(response);

                this.b_adminTeamModify = await this.is_group_permmision(this.group_id, "team", "modify");
                this.b_isMemberModify  = await this.is_permmisionCheck(this.group_id, this.team_id, "member", "modify");
                this.b_isAuthChange    = false;
                
                this.b_isInvite = await this.is_permmisionCheck(this.group_id, this.team_id, "member", "invite");

                for( const resident of response.data.data.resident_list ) {
                    if( resident.cars != null && resident.cars.length > 0 ) {
                        resident.cars = resident.cars.filter(car => car.car_info.state == 'APPROVE');
                    }
                }

                this.member_data.splice(0, this.member_data.length);
                this.member_data = this.member_data.concat(response.data.data.resident_list);

                this.$nextTick(() => this.kakaoShareInit());
            })
            .catch((e) => {
                this.hodu_error_process(e, true, false);
            })

    }

    /**
     * 호두홈 입주자 승인
     */
    async approve_resident(index) : Promise<void> {

        try {

            const body = {
                "state" : "APPROVE"
            }

            const response = await this.hodu_api_call(`api/v1/home/resident/${this.scope_group_team_option.biz_id}/${this.member_data[index].audit_user_id}?is_basic=false`, API_METHOD.PUT, body);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("입주자 거절 처리 중 오류 발생");
            }

            if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
                this.residentSearch();
            }
                
        } catch(e) {
            this.hodu_show_dialog("cancel", "입주자 승인 처리 중 오류 발생", ['확인']);
            this.hodu_error_process(e, false, false, true);
        }

        
    }

    /**
     * 호두홈 입주자 거절
     */
    async reject_resident(index) : Promise<void> {

        this.doSetCommonInputModalInfo?.({
            show_modal : true,
            title : '거절사유입력',
            subtitle : '거절사유',
            content : '',
            placeholder : '거절사유를 입력해주세요',
            save : async(comment) => {

                try {

                    const body = {
                        "state" : "REJECT",
                        "comment" : comment
                    }
                    
                    const response = await this.hodu_api_call(`api/v1/home/resident/${this.scope_group_team_option.biz_id}/${this.member_data[index].audit_user_id}?is_basic=false`, API_METHOD.PUT, body)

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("입주자 거절 처리 중 오류 발생");
                    }

                    if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
                        this.residentSearch();
                    }

                } catch(e) {
                    this.hodu_show_dialog("cancel", "입주자 거절 처리 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            
            }
        });
    }

    /**
     * 권한 체크
     */
    async is_permmisionCheck(pi_groupId, pi_teamId, ps_cate, ps_type) : Promise<boolean> {
        let lb_flag : boolean = false;

        // 그룹 멤버조회 권한
        if ( pi_groupId > 0 && pi_teamId == 0 ) {
            lb_flag = this.is_group_permmision(pi_groupId, ps_cate, ps_type);
        }
        else {
            // 그룹에 관리자 및 팀 수정권한이 있는 사람들은 무조건 통과
            if ( this.b_adminTeamModify ) {
                lb_flag = true;
            }
            else {
                lb_flag = this.is_team_permmision(pi_teamId, ps_cate, ps_type);
            }
        }

        return lb_flag;
    }

    /**
     * 그룹 팀 나가기
     */
    group_team_out() : void {
        let param_data : GroupListConfirmModalData = {
            modal_type       : "my_out",
            type             : (this.team_id > 0 ? 'TEAM' : 'GROUP'),
            group_id         : this.group_id,
            team_id          : this.team_id,
            color            : this.group_team_option.group_team_color,
            image            : this.group_team_option.group_team_image,
            description      : this.group_team_option.group_team_descript,
            name             : this.group_team_option.group_team_name,
            user_id          : this.user_id!,
            is_appr_required : false,
            appr_state       : "",
            member_created   : ""
        }

        window["groupListConfirmCancel"] = this.groupListConfirmCancel;
        window["groupListConfirm"]       = this.groupListConfirm;

        this.group_modal_info.show_group_list_confirm = true;
        this.group_modal_info.data = param_data;
        this.doSetGroupModalInfo(this.group_modal_info);
    }

    /**
     * 탈퇴 모달창 아니오
     */
    groupListConfirmCancel() : void {
        this.group_modal_info.show_group_list_confirm = false;
        this.group_modal_info.data = null;
        this.doSetGroupModalInfo(this.group_modal_info);
    }    

    /**
     * 탈퇴 모달창 예
     */
    async groupListConfirm(po_data) : Promise<void> {
        this.group_modal_info.show_group_list_confirm = false;
        this.group_modal_info.data = null;
        this.doSetGroupModalInfo(this.group_modal_info);

        if ( po_data.modal_type == "my_out" ) {
            await this.groupTeamMemberDelete("", po_data.group_id, po_data.team_id, po_data.user_id);
        }
    }

    /**
     * 탈퇴 추방 API 호출
     */
    async groupTeamMemberDelete(ps_type, pi_group_id, pi_team_id, pi_user_id) : Promise<void> {
        const vue = this;
        let url = "";

        if ( pi_team_id > 0 ) {
            url = `api/v1/teams/teamMemberDelete/${pi_group_id}/${pi_team_id}/${pi_user_id}`;
        }
        else {
            url = `api/v1/groups/groupMemberDelete/${pi_group_id}/${pi_user_id}`;
        }

        await this.hodu_api_call(url, API_METHOD.DELETE, null)
            .then(async(response) => {
                if ( ps_type == "P" ) {

                    if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
                        this.residentSearch();
                    }

                    else {
                        this.getGroupMember();
                    }
                    
                    
                }
                else {
                    if ( this.team_id == 0 ) {
                        this.doGroupId(0);
                        this.doTeamId(0);
                    }
                    else {
                        this.doTeamId(0)
                    }

                    if( this.scope != OWNER_TYPE.PERSONAL ) {
                        // scope 등을 쓰는 computed 데이터가 있을때 먼저 이동 하지 않으면 오류 발생함
                        this.hodu_router_push(`/?${new Date().getTime()}`);
                        
                        this.doSetGroupMenuClose(false);

                        this.doSetStartDate(new Date());
                        this.doSetCalendarId(`personal-${this.user_id}`);
                        this.doSetScope(OWNER_TYPE.PERSONAL);  
                        this.doSetScopeGroupId(0);  
                        this.doSetScopeTeamId(0);  
                        this.doSetScopeGroupTeamOption({
                            group_team_name : '',
                            group_team_color : '#FFFFFF',
                            group_team_image : '',
                            group_team_descript : '',
                            biz_id : '',
                            biz_type : ''
                        });

                        await this.hodu_loading_timer_exit();
                        await this.hodu_loading();

                        setTimeout(() => {
                            // @ts-ignore
                            $('.left_inbox02').mCustomScrollbar('destroy');
                            if( window['left_inbox02_mCustomScrollbar'] != null ) { window['left_inbox02_mCustomScrollbar'](); }
                        }, 100);

                        if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
                    }

                    else {
                        vue.movePrevPage();
                    }
                }

                return;
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });            
    }

    /**
     * 그룹 팀 추방
     */
    group_team_user_out(data) : void {
        let param_data : GroupTeamOtherOutModalData = {
            group_id : this.group_id,
            team_id : this.team_id,
            user_id : data.user_id,
            user_name : data.user_info.user_name,
            image : (data.user_info.user_pic ? data.user_info.user_pic : ""),
            user_phone_number : (data.user_info.user_phone_number ? data.user_info.user_phone_number : ""),
            user_email : (data.user_info.user_email ? data.user_info.user_email : ""),
            role : data.role ? data.role : data.group_role ? data.group_role[0] : data.team_role[0] 
        }

        window["groupTeamOtherOutCancel"]  = this.groupTeamOtherOutCancel;
        window["groupTeamOtherOutConfirm"] = this.groupTeamOtherOutConfirm;        

        this.group_team_other_out_info.show_modal = true;
        this.group_team_other_out_info.data = param_data;
        this.doSetGroupTeamOtherOutModalInfo(this.group_team_other_out_info)
    }

    /**
     * 추방 모달창 아니오
     */
    groupTeamOtherOutCancel() : void {
        this.group_team_other_out_info.show_modal = false;
        this.group_team_other_out_info.data = null;
        this.doSetGroupTeamOtherOutModalInfo(this.group_team_other_out_info);
    }

    /**
     * 추방 모달창 예
     */
    async groupTeamOtherOutConfirm(po_data) : Promise<void> {
        const vue = this;

        this.group_team_other_out_info.show_modal = false;
        this.group_team_other_out_info.data = null;
        this.doSetGroupTeamOtherOutModalInfo(this.group_team_other_out_info);

        await this.groupTeamMemberDelete("P", po_data.group_id, po_data.team_id, po_data.user_id);
    }

    /**
     * 권한변경
     */
    changeAuth(p_user_id, p_old_auth, p_auth) : void {
        if ( p_old_auth == p_auth ) {
            return;
        }

        if( p_auth == this.GroupAuth.ADMIN ) {
            this.hodu_show_dialog("alert", "정말 최종관리자를 위임 하시겠습니까?", ['아니오', '예'], [
                () => {},
                async() => {
                    try {
                        let url = `api/v1/groups/updateGroupMemberPermmision/${this.group_id}/${p_auth}/${p_user_id}`;
                        const response = await this.hodu_api_call(url, API_METHOD.PUT, null);

                        if( !response || !this.isHttpStatusSuccess(response.status) ) {
                            throw new Error("최종관리자 위임 중 오류 발생");
                        }

                        if ( p_user_id == this.user_id ) {
                            await this.get_group_role_service();
                        }
                        
                        if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
                            await this.residentSearch();
                        }

                        else {
                            await this.getGroupMember();
                        }

                    } catch(e) {
                        this.hodu_error_process(e, true, false);
                    }
                }
            ]);
            return;
        }
        
        const vue = this;
        let url = "";

        if ( this.group_id > 0 && this.team_id == 0 ) {
            url = `api/v1/groups/updateGroupMemberPermmision/${this.group_id}/${p_auth}/${p_user_id}`;
        }
        else {
            url = `api/v1/teams/updateTeamMemberPermmision/${this.team_id}/${this.group_id}/${p_auth}/${p_user_id}`;
        }

        this.hodu_api_call(url, API_METHOD.PUT, null)
            .then(async(response) => {
                if ( p_user_id == this.user_id ) {
                    await this.get_group_role_service();
                }
                
                if( this.isHoduH(this.scope_group_team_option.biz_type ? this.scope_group_team_option.biz_type : '') ) {
                    vue.residentSearch();
                }

                else {
                    await vue.getGroupMember();
                }
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 권한설정 페이지 이동
     */
    groupPermmisionGO() : void {
        this.doGroupId(this.group_id);
        this.doTeamId(this.team_id);
        this.doGroupTeamOption(this.group_team_option);

        this.hodu_router_push(`/group/${new Date().getTime()}/permmision`);
    }

    /**
     * 승인
     */
    approve() : void {
        this.doGroupId(this.group_id);
        this.doTeamId(this.team_id);
        this.doGroupTeamOption(this.group_team_option);
        
        this.hodu_router_push(`/group/${new Date().getTime()}/approve`);
    }

    /**
     * 이미지 에러
     */
    imageError(event) : void {
        event.target.src = require('./../assets/images/contents/im_photoB.gif');
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {
        // @ts-ignore
        $('#group_member_scroll').mCustomScrollbar('destroy');
        
    	this.setScroll();
    }

    /**
     * 검색옵션
     */
    groupMemberSearchOption() : void {

    }

    /**
     * 멤버초대
     */
    groupMemberInvite() : void {
        // this.b_openInvite = !this.b_openInvite;

        // if ( !this.b_openInvite ) {
        //     $('#modalMemRegNm').val("");
        //     $('#modalMemRegPh').val("");
        // }

        this.doSetCommonSelectFriendAndGroupTeamModalInfo?.({
            show_modal : true,
            title : "멤버 초대",
            calendar_scope_group_id : this.group_id,
            calendar_scope_team_id : this.team_id,
            use_user_select_only : true,
            callback : async(selected_info) => {
                try {

                    const response = await this.hodu_api_call(`api/v1/groups/invite`, API_METHOD.POST, {
                        "user_ids" : selected_info.selected_user_ids,
                        "group_id" : this.group_id,
                        "team_id" : this.team_id
                    });

                    console.log(response);

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("멤버 초대 중 오류 발생");
                    }

                    // await this.getGroupMember();
                    await window['group_member_reload']?.();

                } catch(e) {
                    this.hodu_show_dialog("cancel", "멤버 초대 중 오류 발생", ['확인']);
                    this.hodu_error_process(e, false, false, true);
                }
            }
        });
    }

    /**
     * 멤버 초대 - 가입 권유 알림 보내기
     */
    async sendGroupInviteMms() : Promise<void> {
        let regex = /^[0-9]*$/;

        let modalMemRegNm : string = String($('#modalMemRegNm').val());
        let modalMemRegPh : string = String($('#modalMemRegPh').val());

        if ( modalMemRegNm.trim().length < 1 ) {
            this.hodu_show_dialog("alert", "이름을 입력해주세요", ['확인']);
            return;
        }

        if ( regex.test("01056757622") == false ) {
            this.hodu_show_dialog("alert", "전화번호는 숫자만 입력해주세요", ['확인']);
            return;
        }

        if ( modalMemRegPh.trim().length < 1 ) {
            this.hodu_show_dialog("alert", "전화번호를 입력해주세요", ['확인']);
            return;
        }

        await this.hodu_api_call(`api/v1/groups/invite/${this.group_id}/${this.team_id}`, API_METHOD.POST, {user_name: modalMemRegNm, user_phone_number: modalMemRegPh, group_team_name: this.group_team_option.group_team_name})
            .then(async(response) => {
                $(".inviteMateDiv").addClass("sent");
            })
            .catch(async(e) => {
                this.hodu_error_process(e, true, false);
            });
    }

    /**
     * 리스트 권한 드롭다운 ON / OFF
     */
    dropdownOnOff(original_member : any) : void {

        let original_member_role_on : boolean | undefined = original_member.member_role_on;
        let original_user_id : number = original_member.user_id;
        let original_index : number = -1;

        if( original_user_id == this.user_id ) {
            return;
        }

        const member_count : number = this.member_data.length;
        for( let i = 0; i < member_count; i++ ) {
            const member = JSON.parse(JSON.stringify(this.member_data[i]));
            member.member_role_on = false;
            this.member_data.splice(i, 1, member);

            if( original_user_id == member.user_id ) { original_index = i; }
        }

        original_member_role_on = original_member_role_on ? !original_member_role_on : true;
        original_member.member_role_on = original_member_role_on;
        this.member_data.splice(original_index, 1, original_member);
    }
    
    /**
     * 멤버 리스트 이름 기준 정렬
     */
    memberNameSort() : void {

        // 이미 NAME Sort 중이라면
        if( this.member_sort_target == "NAME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.member_sort_direction ) {
                case "ASC":
                    this.member_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.member_sort_target = null;
                    break; 
            }
        }
        
        // NAME Sort가 아니였다면
        else {
            this.member_sort_target = "NAME";
            this.member_sort_direction = "ASC";
        }
        
    }

    /**
     * 멤버 리스트 직급 기준 정렬
     */
    memberRoleSort() : void {

        // 이미 ROLE Sort 중이라면
        if( this.member_sort_target == "ROLE" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.member_sort_direction ) {
                case "ASC":
                    this.member_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.member_sort_target = null;
                    break; 
            }
        }
        
        // ROLE Sort가 아니였다면
        else {
            this.member_sort_target = "ROLE";
            this.member_sort_direction = "ASC";
        }
        
    }

    /**
     * 입주자 차량 요청 승인 모달
     */
    showHomeResidentCarRequestModal(resident : any, car : any) : void {
        // if( this.doSetHomeResidentCarRequestModalInfo == null || car.car_info.is_apply == true ) { return; }

        this.doSetHomeResidentCarRequestModalInfo?.({
            show_modal : true,
            resident : resident,
            car : car
        });
    }

    /**
     * 카카오 링크를 이용한 그룹&팀 초대   
     */
    async kakaoShareInit() : Promise<void> {
        if( !Kakao.isInitialized() ) { await Kakao.init('c18796cb68948dbecc66ea05f7fa7773'); }
    }

    async kakaoShare() {

        try {

            // 클릭으로 이벤트를 추가했는지 boolean 값 체크, 했다면 그냥 빠져나감
            if( this.kakao_share_init == true ) {
                return;
            }

            // 제목
            let title : string = "";
            let content : string = ``;
            let params : string  = "";

            let dynamic_link = '';

            for( const group_team of this.all_group_data_temp ) {
                
                if( group_team.group_id != this.group_id || group_team.team_id != this.team_id ) {
                    continue;
                }

                params = `group_id=${this.group_id}&group_name=${group_team.group_name}&team_id=${this.team_id}&noti_type=PUSH&noti_sub_type=GROUP_TEAM_INVITE&biz_type=${this.scope_group_team_option.biz_type}`;
                if( this.team_id > 0 ) {
                    params += `&team_name=${group_team.team_name}`;
                }

                dynamic_link = `${this.DYNAMIC_LINK_PREFIX}${encodeURIComponent(params)}${this.DYNAMIC_LINK_SUFFIX}`;

                // shorten url
                dynamic_link = await this.hodu_shorten_url(dynamic_link);

                if( this.team_id > 0 ) {
                    title = `[${group_team.group_name}-${group_team.team_name}]`;
                    content = `팀에 초대합니다\n\n* 앱 설치 및 초대 바로가기 ↓↓↓\n${dynamic_link}\n\n호두가 설치되어 있는 경우\n아래 '호두 실행'을 클릭하여\n해당 팀에 가입하실 수 있습니다`;
                }
                else {
                    title = `[${group_team.group_name}]`;
                    content = `그룹에 초대합니다\n\n* 앱 설치 및 초대 바로가기 ↓↓↓\n${dynamic_link}\n\n호두가 설치되어 있는 경우\n아래 '호두 실행'을 클릭하여\n해당 그룹에 가입하실 수 있습니다`;
                }

                break;
            }

            let send_text : string = title + "\n" + content;

            Kakao.Link.createDefaultButton({
                container: '#kakao_link_btn',
                objectType: 'text',
                text : send_text,
                link: {
                    androidExecParams: params,
                    iosExecParams: params,
                },
                buttons: [
                    {
                        title: '호두 실행',
                        link: {
                            androidExecParams: params,
                            iosExecParams: params,
                        }
                    },
                ]
            });
            
            $('#kakao_link_btn').trigger('click');

            this.kakao_share_init = true;
        }
        catch(e) {
            console.log(e);
        }
    }

    /**
     * 그룹 멤버 엑셀로 가져오기
     */
    groupMemberImportExcel() {
        this.doSetGroupMemberImportModalInfo?.({
            show_modal : true,
            group_id : this.group_id,
            team_id : this.team_id
        });
    }
    
}
