
import { Component, Vue, Mixins, Prop, Emit } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';
import { namespace } from 'vuex-class';
import { GroupModalInfo, GroupListConfirmModalData } from '@/store/modules/ModalInfo';

const GroupInfo = namespace('GroupInfo');
const ModalInfo = namespace('ModalInfo');
const dateFormat = require('dateformat');

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component
export default class GroupTeamList extends Mixins(VueHoduCommon) {
    /**
     * ModalInfo.State
     */
    @ModalInfo.State  group_modal_info !: GroupModalInfo;

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetGroupModalInfo ?: any;

    /**
     * Prop 변수 선언부
     */
    @Prop() group_id  !: number;
    @Prop() team_id   !: number;
    @Prop() data      !: any;
    @Prop() event_bus !: Vue;

    group_team_sort_target : string | null = null;
    group_team_sort_direction : string = "ASC";

    /**
     * 변수 선언부
     */
    list : any = [];
    original_list : any = [];
    b_isTeamAuth : boolean = false;

    async mounted() {
        this.b_isTeamAuth = await this.is_group_permmision(this.group_id, "team", "create");
        this.getGroupTeam();
        this.setScroll();

        if( this.event_bus ) {
            this.event_bus.$on('handleResizeGroupTeamList', this.handleResize);
        }

        
    }

    setScroll() : void {
        // this.$nextTick(() => {
        //     // @ts-ignore
        //     $('#grpTeamScroll').mCustomScrollbar({
        //         axis : 'y',
        //         scrollbarPosition : 'outside',
        //         mouseWheelPixels : 100,
        //         scrollInertia : 60,
        //         autoDraggerLength : false,
        //         setHeight : 170,
        //     });
        // });
    }

    /**
     * 팀 조회
     */
    async getGroupTeam() : Promise<void> {
        const vue = this;

        await this.get_group_role_service();

        await this.hodu_api_call(`api/v1/groupsweb/getGroupTeamList/${this.group_id}`, API_METHOD.GET, null, false)
            .then(async(response) => {
                this.list = [];

                if ( !response.data.data.group ) {
                    this.hodu_show_dialog("alert", "그룹 조회중 오류가 발생하였습니다[GroupTeamList]", ['확인']);
                }

                this.list.push(response.data.data.group);

                $.each(response.data.data.team_list, function(key, data) {
                    if ( data.user_group_team_color.length == 9 ) {
                        data.user_group_team_color = "#" + data.user_group_team_color.substring(3, data.user_group_team_color.length);
                    }

                    data.team_member_created = dateFormat(data.team_member_created, 'yyyy.mm.dd');

                    vue.list.push(data);
                });

                this.original_list = JSON.parse(JSON.stringify(this.list));
            })
            .catch(async(e) => {
                
            });

        $( "#group_team_list > .notYet.waiting .status a" ).mouseout(function() {
            $(this).text('요청 중');
        }).mouseover(function() {
            $(this).text('요청 취소');
        });
    }

    /**
     * 그룹 팀 보기 변경
     */
    //@Emit('increment')
    group_team_change(data) : void {
        this.$emit('init', data.group_id, (data.list_type == "GROUP" ? 0 : data.team_id), "")
    }

    /**
     * 팀 가입
     */
    clickTeamRow(data) : void {
        if ( data.team_user_delete_flag == true ) {
            this.hodu_show_dialog("alert", "해당 팀에서 탈퇴된 사용자 입니다", ['확인']);
            return;
        }

        let param_data : GroupListConfirmModalData = {
            modal_type       : "join_cancel",
            type             : "TEAM",
            group_id         : data.group_id,
            team_id          : data.team_id,
            color            : data.user_group_team_color,
            image            : data.team_info.image,
            description      : data.team_info.description,
            name             : data.team_info.team_name,
            user_id          : data.user_id,
            is_appr_required : data.team_policy.is_appr_required,
            appr_state       : data.appr_state,
            member_created   : (data.user_id > 0 ? dateFormat(data.team_member_created, 'yyyy.mm.dd') : "")
        }

        window["groupListConfirmCancel"] = this.groupListConfirmCancel;
        window["groupListConfirm"]       = this.groupListConfirm;

        this.group_modal_info.show_group_list_confirm = true;
        this.group_modal_info.data = param_data;
        this.doSetGroupModalInfo(this.group_modal_info);
    }

    /**
     * 모달창 아니오
     */
    groupListConfirmCancel() : void {
        this.group_modal_info.show_group_list_confirm = false;
        this.group_modal_info.data = null;
        this.doSetGroupModalInfo(this.group_modal_info);
    }

    /**
     * 모달창 예
     */
    groupListConfirm(po_data) : void {
        this.group_modal_info.show_group_list_confirm = false;
        this.group_modal_info.data = null;

        // 가입
        if ( po_data.user_id == 0 || po_data.appr_state == "" ) {
            this.$http.post(`api/v1/teams/teamMemberInsert/${po_data.group_id}/${po_data.team_id}`, {is_appr_required: po_data.is_appr_required})
                .then(async(response) => {
                    this.getGroupTeam();
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
        }

        // 승인 취소
        if ( po_data.user_id > 0 && (po_data.appr_state == "R" || po_data.appr_state == "E" || po_data.appr_state == "T") ) {
            this.$http.delete(`api/v1/teams/teamMemberDelete/${po_data.group_id}/${po_data.team_id}/${this.user_id}`)
                .then(async(response) => {
                    this.getGroupTeam();
                })
                .catch(async(e) => {
                    this.hodu_error_process(e, true, false);
                });
        }

        this.doSetGroupModalInfo(this.group_modal_info);
    }

    /**
     * 팀 추가
     */
    teamAdd() : void {
        this.$emit('init', 0, 0, "team_add")
    }

    /**
     * 호두키즈 여부
     */
    isHoduKids() : boolean {
        return this.data != null && this.data.group_info != null && this.data.group_info.project_type == 'kids';
    }

    /**
     * 이름 기준 정렬
     */
    nameSort() : void {
        // 이미 NAME Sort 중이라면
        if( this.group_team_sort_target == "NAME" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.group_team_sort_direction ) {
                case "ASC":
                    this.group_team_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.group_team_sort_target = null;
                    break; 
            }
        }
        
        // NAME Sort가 아니였다면
        else {
            this.group_team_sort_target = "NAME";
            this.group_team_sort_direction = "ASC";
        }

        if( this.group_team_sort_target == null ) { this.setListToOriginalList(); }
        else { this.listSort(); }
    }

    /**
     * 멤버 수 기준 정렬
     */
    countSort() : void {
        // 이미 COUNT Sort 중이라면
        if( this.group_team_sort_target == "COUNT" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.group_team_sort_direction ) {
                case "ASC":
                    this.group_team_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.group_team_sort_target = null;
                    break; 
            }
        }
        
        // COUNT Sort가 아니였다면
        else {
            this.group_team_sort_target = "COUNT";
            this.group_team_sort_direction = "ASC";
        }

        if( this.group_team_sort_target == null ) { this.setListToOriginalList(); }
        else { this.listSort(); }
    }

    /**
     * 권한 기준 정렬
     */
    roleSort() : void {
        // 이미 ROLE Sort 중이라면
        if( this.group_team_sort_target == "ROLE" ) {
            // ASC -> DESC -> 정렬 없음 순으로 변경 되어야 한다
            switch( this.group_team_sort_direction ) {
                case "ASC":
                    this.group_team_sort_direction = "DESC";
                    break;
            
                case "DESC":
                    this.group_team_sort_target = null;
                    break; 
            }
        }
        
        // ROLE Sort가 아니였다면
        else {
            this.group_team_sort_target = "ROLE";
            this.group_team_sort_direction = "ASC";
        }

        if( this.group_team_sort_target == null ) { this.setListToOriginalList(); }
        else { this.listSort(); }
    }
    
    /**
     * list를 original_list의 데이터로 변경
     */
    setListToOriginalList() : void {
        this.list = JSON.parse(JSON.stringify(this.original_list));
    }
    
    /**
     * group_team_list 정렬
     */
    listSort() : void {
        this.list.sort((o1, o2) : number => {
            if( o1.list_type == 'GROUP' && o2.list_type == 'TEAM'  ) { return -1; }
            else if( o1.list_type == 'TEAM' && o2.list_type == 'GROUP' ) { return 1; }
            else if( o1.list_type == 'GROUP' && o2.list_type == 'GROUP' ) { return 0; }
            else{
                // 이름 오름차순
                if( this.group_team_sort_target == "NAME" && this.group_team_sort_direction == "ASC" ) {
                    if( o1.team_info.team_name  > o2.team_info.team_name ) { return  1; } 
                    if( o1.team_info.team_name  < o2.team_info.team_name ) { return -1; } 
                    if( o1.team_info.team_name == o2.team_info.team_name ) { return  0; }
                }

                // 이름 내림차순
                if( this.group_team_sort_target == "NAME" && this.group_team_sort_direction == "DESC" ) {
                    if( o1.team_info.team_name  > o2.team_info.team_name ) { return -1; } 
                    if( o1.team_info.team_name  < o2.team_info.team_name ) { return  1; } 
                    if( o1.team_info.team_name == o2.team_info.team_name ) { return  0; }
                }

                // 멤버 수 오름차순
                if( this.group_team_sort_target == "COUNT" && this.group_team_sort_direction == "ASC" ) {
                    if( o1.team_user_count  > o2.team_user_count ) { return  1; } 
                    if( o1.team_user_count  < o2.team_user_count ) { return -1; } 
                    if( o1.team_user_count == o2.team_user_count ) { return  0; }
                }

                // 멤버 수 내림차순
                if( this.group_team_sort_target == "COUNT" && this.group_team_sort_direction == "DESC" ) {
                    if( o1.team_user_count  > o2.team_user_count ) { return -1; } 
                    if( o1.team_user_count  < o2.team_user_count ) { return  1; } 
                    if( o1.team_user_count == o2.team_user_count ) { return  0; }
                }

                // 권한 오름차순
                if( this.group_team_sort_target == "ROLE" && this.group_team_sort_direction == "ASC" ) {
                    if( o1.team_role.length < 1 && o2.team_role.length > 0 ) { return -1; }
                    if( o1.team_role.length > 0 && o2.team_role.length < 1 ) { return  1; }
                    
                    if( o1.team_role[0] == o2.team_role[0] ) { return 0; }
                    else {
                        if( o1.team_role[0] == this.GroupAuth.TEAM_MANAGER ) { return  1; }
                        if( o2.team_role[0] == this.GroupAuth.TEAM_MANAGER ) { return -1; }
                    }
                }

                // 권한 내림차순
                if( this.group_team_sort_target == "ROLE" && this.group_team_sort_direction == "DESC" ) {
                    if( o1.team_role.length < 1 && o2.team_role.length > 0 ) { return  1; }
                    if( o1.team_role.length > 0 && o2.team_role.length < 1 ) { return -1; }
                    
                    if( o1.team_role[0] == o2.team_role[0] ) { return 0; }
                    else {
                        if( o1.team_role[0] == this.GroupAuth.TEAM_MANAGER ) { return -1; }
                        if( o2.team_role[0] == this.GroupAuth.TEAM_MANAGER ) { return  1; }
                    }
                }
            }

            return 0;
        });
    }

    /**
     * 그룹 즐겨찾기 변경
     */
    async groupFavorite(group_id) : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groupFavorite/${group_id}`, API_METHOD.POST);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("그룹 즐겨찾기 변경 중 오류 발생");
            }
                
        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "즐겨찾기 변경 중 오류 발생", ['확인']);
        } finally {
            this.getGroupTeam();
        }
        
    }


    /**
     * 팀 즐겨찾기 변경
     */
    async teamFavorite(group_id, team_id) : Promise<void> {

        const target = this.original_list.filter(item => item.group_id == group_id && item.team_id == team_id && item.appr_state == 'A');
        if( target.length < 1 ) return;

        try {
            const response = await this.hodu_api_call(`api/v1/teams/favorite/${group_id}/${team_id}`, API_METHOD.POST);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) ) {
                throw new Error("팀 즐겨찾기 변경 중 오류 발생");
            }

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog('cancel', "즐겨찾기 변경 중 오류 발생", ['확인']);
        } finally {
            this.getGroupTeam();
        }

    }

    /**
     * 반 삭제
     */
    async deleteClass(team) : Promise<void> {

        this.hodu_show_dialog('cancel', `정말로 삭제하시겠습니까?`, ['아니오', '예'], [
            () => {},
            async() => {
                try {
                    
                    const response = await this.hodu_api_call(`api/v1/kids/class`, API_METHOD.DELETE, {
                        "group_id" : team.group_id,
                        "team_id" : team.team_id
                    });

                    if( !response || !this.isHttpStatusSuccess(response.status) ) {
                        throw new Error("반 삭제 중 오류 발생");
                    }

                    this.getGroupTeam();

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog('cancel', "반 삭제 중 오류 발생", ['확인']);
                }
            }
        ])        

    }

    /**
	 * 단어 오브젝트 생성
	 */
	makeWord() : any {

        let word = {
            "group" : "",
            "team" : "",
            "admin" : "",
            "group_manager" : "",
            "group_sub_manager" : "",
            "group_user" : "",
            "team_manager" : "",
            "team_user" : "",
            "member" : "",
            "group_manager_not_exist" : "",
            "group_sub_manager_not_exist" : "",
            "group_user_not_exist" : "",
            "team_manager_not_exist" : "",
            "team_user_not_exist" : ""
        };
        
		if( this.data == null || this.data.group_info == null ) {
            return word;
		}

        word = {
            "group" : "그룹",
            "team" : "팀",
            "admin" : "최종관리자",
            "group_manager" : "관리자",
            "group_sub_manager" : "부관리자",
            "group_user" : "멤버",
            "team_manager" : "팀장",
            "team_user" : "팀원",
            "member" : "멤버",
            "group_manager_not_exist" : "관리자가 존재하지 않습니다",
            "group_sub_manager_not_exist" : "부관리자가 존재하지 않습니다",
            "group_user_not_exist" : "멤버가 존재하지 않습니다",
            "team_manager_not_exist" : "팀장이 존재하지 않습니다",
            "team_user_not_exist" : "팀원이 존재하지 않습니다"
        };

        if( this.isHoduH(this.data.group_type) == true ) {
            word = {
                "group" : "아파트",
                "team" : "동",
                "admin" : "최종관리자",
                "group_manager" : "관리자",
                "group_sub_manager" : "보안실",
                "group_user" : "입주자",
                "team_manager" : "동장",
                "team_user" : "입주자",
                "member" : "입주자",
                "group_manager_not_exist" : "관리자가 존재하지 않습니다",
                "group_sub_manager_not_exist" : "보안실 근무자가 존재하지 않습니다",
                "group_user_not_exist" : "입주자가 존재하지 않습니다",
                "team_manager_not_exist" : "동장이 존재하지 않습니다",
                "team_user_not_exist" : "입주자가 존재하지 않습니다"
            };
        }

        else if( this.isHoduKids() == true ) {
            word = {
                "group" : "원",
                "team" : "반",
                "admin" : "최종관리자",
                "group_manager" : "선생님",
                "group_sub_manager" : "선생님",
                "group_user" : "원아",
                "team_manager" : "선생님",
                "team_user" : "원아",
                "member" : "원아",
                "group_manager_not_exist" : "선생님이 존재하지 않습니다",
                "group_sub_manager_not_exist" : "선생님이 존재하지 않습니다",
                "group_user_not_exist" : "원아가 존재하지 않습니다",
                "team_manager_not_exist" : "선생님이 존재하지 않습니다",
                "team_user_not_exist" : "원아가 존재하지 않습니다"
            };
        }

        return word;
	}

    /**
     * 이미지 에러
     */
    groupImageError(event, data) : void {
        if( data.group_info && data.group_info.image && data.group_info.image.length > 0 ) {
            data.group_info.image = "";
        }
        else if( data.team_info && data.team_info.image && data.team_info.image.length > 0 ) {
            data.team_info.image = "";
        }
        this.$forceUpdate();
    }

    handleResize() : void {
        // this.$nextTick(() => {
        //     // @ts-ignore
        //     $('#grpTeamScroll').mCustomScrollbar('destroy');
            
        //     this.setScroll();
        // });
    }

}
