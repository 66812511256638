
import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { OWNER_TYPE } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const OrganizationInfo = namespace('OrganizationInfo')

import { organization_enum } from '@/model/organization';

@Component({
    components : {
        
    }
})
export default class LeftGroupMember extends Mixins(VueHoduCommon) {

    // id : string = "grupMemManage";
    // text : string = "멤버";
    
    /**
     * @OrganizationInfo.Action
     */
    @OrganizationInfo.Action doSetOrganizationMemberSelectedTab ?: (params : organization_enum.ORGANIZATION_SETTING_TAB) => void;

    mounted() : void {
        // this.id = this.isHoduH(this.scope_group_team_option.biz_type) ? 'menu_resident' : 'grupMemManage';
        // this.text = this.isHoduH(this.scope_group_team_option.biz_type) ? '입주자' : '멤버';
    }

    /**
     * 그룹 멤버 리스트로 이동
     */
    goGroupMemberList() : void {
        // if( this.left_control_box_flag == true ) { this.doSetLeftControlBoxFlag(false); }

        this.doGroupId(this.scope_group_id);
        this.doTeamId(this.scope_team_id);
        this.doGroupTeamOption({
            group_team_name : this.scope_group_team_option.group_team_name,
            group_team_color : this.scope_group_team_option.group_team_color,
            group_team_image : this.scope_group_team_option.group_team_image,
            group_team_descript : this.scope_group_team_option.group_team_descript,
            biz_id : this.scope_group_team_option.biz_id
        });

        // 전자결재 또는 근태현황 중 하나라도 사용시
        if( (this.isEnableGroupFeature(this.scope_group_id, 'attendance') || this.isEnableGroupFeature(this.scope_group_id, 'approval')) ) {
            this.doSetOrganizationMemberSelectedTab?.(organization_enum.ORGANIZATION_SETTING_TAB.MEMBER);
            this.hodu_router_push(`/${this.scope}/${this.scope == OWNER_TYPE.GROUP ? this.scope_group_id : this.scope_team_id}/organization/member/setting?${new Date().getTime()}`);
            return;
        }

        this.hodu_router_push(`/group/${new Date().getTime()}/member`);
    }

    // @Watch('scope_group_team_option', { immediate : false, deep : true })
    // watchScopeGroupTeamOption() : void {
    //     this.id = this.isHoduH(this.scope_group_team_option.biz_type) ? 'menu_resident' : 'grupMemManage';
    //     this.text = this.isHoduH(this.scope_group_team_option.biz_type) ? '입주자' : '멤버';
    // }

}
