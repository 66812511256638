import { ScheduleSearchConfig } from '@/store/modules/CalendarInfo';

/**
 * 로컬스토리지 래핑 클래스
 */
export class HoduLocalStorage {
    getItem(key:string) : string | null {
        return localStorage.getItem(key);
    }
    setItem(key:string, value:string) : void {
        return localStorage.setItem(key,value);
    }
    remove(key:string){
        localStorage.removeItem(key);
    }
    cleare(){
        localStorage.clear();        
    }
    length(){
        return localStorage.length;
    }
}

// user_id : LocalStorageInfo (key, value)로 집어넣을예정
export interface LocalStorageInfo {
    schedule_search_config : ScheduleSearchConfig;
    last_event_created_scope : any;
    last_event_created_color : string;

    filter_info : {
        basic : boolean,
        work : boolean,
        report : boolean,
        meetinglog : boolean,
        vacation : boolean,
        businesstrip : boolean,
    };
}

export const hodu_local_storage : HoduLocalStorage = new HoduLocalStorage();
export const local_storage_info : LocalStorageInfo = {
    
    /**
     * 일정 필터 설정
     */
    schedule_search_config : {
        past_schedule : true,
        personal_schedule : true,
        my_schedule : true,
        shared_schedule : true,
        group_schedule : true,
        hodu_c_schedule : true,
        hodu_d_schedule : true,
        group_appointment : true,
        system_calendar : true,
        group_filter : [],
        team_filter : [],
        group_and_team_filter : [],
        hodu_d_filter : [],
        group_appointment_filter : [],       
        update_tag : 0,
    },

    /**
     * 마지막에 작성한 이벤트의 scope
     */
    last_event_created_scope : {
        scope : null,
        scope_id : null,
    },

    last_event_created_color : "#477FFF",

    /**
     * 오른쪽메뉴 필터 정보
     */
    filter_info : {
        basic : true,
        work : true,
        report : true,
        meetinglog : true,
        vacation : true,
        businesstrip : true,
    }
    
};