import Vue from "vue";
import Router, { Route } from "vue-router";
import store from './store';

Vue.use(Router);

const router : Router = new Router({
    mode: "hash",
    //  base: process.env.BASE_URL,
    routes: [
        /**
         * 예제
         */
        {
            path: "/example",
            name: "Example",
            component: () => import("./pages/Example.vue"),
        },
        /**
         * 예제
         */
        {
            path: "/example/datepicker",
            name: "ExampleDatepicker",
            component: () => import("./pages/ExampleDatepicker.vue"),
        },
        /**
         * 달력
         */
        {
            path: "/",
            name: "Calendar",
            component: () => import("./pages/Calendar.vue"),
        },
        /**
         * 로그인
         */
        {
            path: "/login",
            name: "Login",
            component: () => import("./pages/Login.vue")
        },
        /**
         * 데이터 삭제 로그인
         */
        {
            path: "/delete_data",
            name: "DeleteData",
            component: () => import("./pages/DeleteData.vue")
        },
        /**
         * 비밀번호 찾기
         */
        {
            path: "/find/password",
            name: "FindPassword",
            component: () => import("./pages/FindPassword.vue")
        },
        /**
         * 일정
         */
        {
            path: "/event",
            name: "Event",
            component: () => import("./pages/Event.vue")
        },
        {
            path: "/event/:event_id",
            name: "EventDetail",
            component: () => import("./pages/Event.vue")
        },
        {
            path: "/event_search",
            name: "EventSearch",
            component: () => import("./pages/EventSearch.vue")
        },
        {
            path: "/gallery",
            name: "EventGallery",
            component: () => import("./pages/EventGallery.vue")
        },
        /**
         * 전체 파일함 
         */     
        {
            path: "/filebox",
            name: "GroupFileBox",
            component: () => import("./pages/FileBox.vue")
        },
        /**
         * 그룹 파일함
         */     
        {
            path: "/group/filebox",
            name: "GroupFileBoxNew",
            component: () => import("./pages/GroupFileBoxNew.vue")
        },
        /**
         * 그룹 파일함 디렉토리 진입
         */
        {
            path: "/group/filebox/:dir_id",
            name: "GroupFileList",
            component: () => import("./pages/GroupFileListNew.vue")
        },
        /**
         * 그룹
         */
        {
            path: "/group/:tag",
            name: "GroupList",
            component: () => import("./pages/GroupList.vue")
        },
        // {
        //     path: "/group/:tag/detail",
        //     name: "GroupDetail",
        //     component: () => import("./pages/GroupDetail.vue")
        // },
        {
            path: "/group/:tag/detail",
            name: "GroupDetail",
            component: () => import("./pages/GroupDetail.vue")
        },
        {
            path: "/group/:tag/member",
            name: "GroupMember",
            component: () => import("./pages/GroupMember.vue")
        },
        {
            path: "/group/:tag/approve",
            name: "GroupApprove",
            component: () => import("./pages/GroupApprove.vue")
        },
        {
            path: "/group/:tag/permmision",
            name: "GroupPermmision",
            component: () => import("./pages/GroupPermmision.vue")
        },
        {
            path: "/group/:tag/survey",
            name: "GroupSurvey",
            component: () => import("./pages/GroupSurvey.vue")
        },
        {
            path: "/group/:tag/survey/create",
            name: "GroupSurveyCreate",
            component: () => import("./pages/GroupSurveyCreate.vue")
        },
        {
            path: "/group/:tag/survey/:survey_id",
            name: "GroupSurveyModify",
            component: () => import("./pages/GroupSurveyCreate.vue")
        },
        {
            path: "/group/:tag/board",
            name: "GroupBoard",
            component: () => import("./pages/GroupBoard.vue")
        },
        {
            path: "/group/:tag/board/create",
            name: "GroupBoardCreate",
            component: () => import("./pages/GroupBoardCreate.vue")
        },
        {
            path: "/group/:tag/board/modify/:board_id",
            name: "GroupBoardModify",
            component: () => import("./pages/GroupBoardCreate.vue")
        },
        {
            path: "/group/:tag/board/:board_id",
            name: "GroupBoardDetail",
            component: () => import("./pages/GroupBoardDetail.vue")
        },
        /**
         * 업무
         */
        {
            path: "/work/documentation",
            name: "WorkDocumentation",
            component: () => import("./pages/work/WorkDocumentation.vue")
        },
        {
            path: "/work/:tag/list",
            name: "WorkList",
            component: () => import("./pages/work/WorkList.vue")
        },
        {
            path: "/work/:tag/setting",
            name: "WorkSetting",
            component: () => import("./pages/work/WorkSetting.vue")
        },
        {
            path: "/work/:tag/setting/template",
            name: "WorkTemplateAdd",
            component: () => import("./pages/work/WorkTemplateAdd.vue")
        },
        {
            path: "/work/:tag/setting/template/:template_id",
            name: "WorkTemplateModify",
            component: () => import("./pages/work/WorkTemplateAdd.vue")
        },
        /**
         * 업무일지
         */
        {
            path: "/report",
            name: "ReportList",
            component: () => import("./pages/work/ReportList.vue")
        },
        /**
         * 회의록
         */
        {
            path: "/meetinglog",
            name: "MeetingLogList",
            component: () => import("./pages/work/MeetingLogList.vue")
        },
        /**
         * 메세지
         */
        {
            path: "/message/:tag/send",
            name: "MessageSend",
            component: () => import("./pages/MessageSend.vue")
        },
        {
            path: "/message/:tag/status",
            name: "MessageStatus",
            component: () => import("./pages/MessageStatus.vue")
        },
        {
            path: "/message/:tag/template",
            name: "MessageTemplate",
            component: () => import("./pages/MessageTemplate.vue")
        },
        {
            path: "/message/:tag/template_add",
            name: "MessageTemplateAdd",
            component: () => import("./pages/MessageTemplateAdd.vue")
        },
        /**
         * 그룹 공지사항
         */
        {
            path: "/group/:tag/notice",
            name: "GroupNoticeList",
            component: () => import("./pages/GroupNoticeList.vue")
        },
        {
            path: "/group/:tag/notice_detail",
            name: "GroupNoticeDetail",
            component: () => import("./pages/GroupNoticeDetail.vue")
        },
        {
            path: "/group/:tag/notice_create",
            name: "GroupNoticeCreate",
            component: () => import("./pages/GroupNoticeCreate.vue")
        },
        {
            path: "/group/:tag/notice_create_editor",
            name: "GroupNoticeCreateEditor",
            component: () => import("./pages/GroupNoticeCreateEditor.vue")
        },
        /**
         * 그룹 파일함(최초 페이지)
         */     
        {
            path: "/group/:tag/file",
            name: "GroupFileBox",
            component: () => import("./pages/GroupFileBox.vue")
        },
        /**
         * 그룹 파일함
         */
        {
            path: "/group/:tag/file/:dir_id",
            name: "GroupFileList",
            component: () => import("./pages/GroupFileList.vue")
        },
        /**
         * 그룹 생성
         */
        {
            path: "/group/:tag/create",
            name: "GroupCreate",
            component: () => import("./pages/GroupCreate.vue")
        },
        /**
         * 팀 생성
         */
        {
            path: "/team/:tag/create",
            name: "GroupTeamCreate",
            component: () => import("./pages/GroupTeamCreate.vue")
        },
        /**
         * 프리미엄 그룹 생성
         */
        {
            path: "/biz/:tag/create",
            name: "BizCreate",
            component: () => import("./pages/BizCreate.vue")
        },
        /**
         * 프리미엄 그룹 승인
         */
        {
            path: "/biz/:tag/apply",
            name: "BizApply",
            component: () => import("./pages/BizApply.vue")
        },
        /**
         * Excetion 페이지
         */
        {
            path: "/exception",
            name: "Exception",
            component: () => import("./pages/Exception.vue")
        },
        /**
         * 호두공지 사항
         */
        {
            path: "/hodu/:tag/notice",
            name: "HoduNoticeList",
            component: () => import("./pages/HoduNoticeList.vue")
        },      
        /**
         * 호두공지 작성
         */
        {
            path: "/hodu/:tag/notice_create",
            name: "HoduNoticeCreate",
            component: () => import("./pages/HoduNoticeCreate.vue")
        },     
        /**
         * 호두공지 상세보기
         */
        {
            path: "/hodu/:tag/notice_detail",
            name: "HoduNoticeDetail",
            component: () => import("./pages/HoduNoticeDetail.vue")
        },
        /**
         * 호두닥 프로토타입  
         */
        {
            path: "/doc",
            name: "Doc",
            component: () => import("./pages/Doc.vue")
        },
        /**
         * 환경설정
         */     
        {
            path: "/setting",
            name: "CustomerSetting",
            component: () => import("./pages/CustomerSetting.vue")
        },

        /**
         * 예약 리스트
         */     
        {
            path: "/hospital/:tag/appointment",
            name: "AppointmentList",
            component: () => import("./pages/hodu_d/AppointmentList.vue")
        },
        /**
         * 예약 추가
         */     
        {
            path: "/hospital/:tag/appointment/create",
            name: "AppointmentCreate",
            component: () => import("./pages/hodu_d/AppointmentCreate.vue")
        },
        /**
         * 예약 상세
         */
        {
            path: "/hospital/:tag/appointment/:event_id",
            name: "Appointment",
            component: () => import("./pages/hodu_d/Appointment.vue")
        },
        /**
         * 의사 리스트
         */     
        {
            path: "/hospital/:tag/doctor",
            name: "DoctorList",
            component: () => import("./pages/hodu_d/DoctorAndDepartmentList.vue")
        },
        /**
         * 진료과 리스트
         */     
        {
            path: "/hospital/:tag/department",
            name: "DepartmentList",
            component: () => import("./pages/hodu_d/DoctorAndDepartmentList.vue")
        },
        /**
         * 의사 추가
         */     
        {
            path: "/hospital/:tag/doctor/create",
            name: "DoctorCreate",
            component: () => import("./pages/hodu_d/Doctor.vue")
        },
        /**
         * 의사 조회 및 수정
         */     
        {
            path: "/hospital/:tag/doctor/:doctor_code",
            name: "DoctorDetail",
            component: () => import("./pages/hodu_d/Doctor.vue")
        },
        /**
         * 의사 상세 (임시페이지)
         */     
        {
            path: "/hospital/:tag/doctor/detail",
            name: "DoctorDetailGo",
            component: () => import("./pages/hodu_d/DoctorDetail.vue")
        },
        /**
         * 환자 리스트
         */     
        {
            path: "/hospital/:tag/patient",
            name: "PatientList",
            component: () => import("./pages/hodu_d/PatientList.vue")
        },
        /**
         * 환자 상세
         */     
        {
            path: "/hospital/:tag/patient/:patient_id",
            name: "Patient",
            component: () => import("./pages/hodu_d/Patient.vue")
        },
        /**
         * 병원 세팅
         */     
        {
            path: "/hospital/:tag/setting",
            name: "HospitalSetting",
            component: () => import("./pages/hodu_d/HospitalSetting.vue")
        },
        /**
         * 병원 리스트
         */
        {
            path: "/hospital",
            name: "HospitalList",
            component: () => import("./pages/hodu_d/HospitalList.vue")
        },
        /**
         * 호두닥 파일함
         */
        {
            path: "/hospital/file/:biz_id",
            name: "HospitalFileList",
            component: () => import("./pages/hodu_d/HospitalFileList.vue")
        },
        /**
         * 채팅
         */
        {
            path: "/chatting/:tag",
            name: "Chatting",
            component: () => import("./pages/chatting/Chatting.vue")
        },
        /**
         * 임시보관함
         */
         {
            path: "/temp",
            name: "TempBox",
            component: () => import("./pages/temps/TempsBox.vue")
        },
        /**
         * 방문차량 리스트
         */
        {
            path: "/home/visitant/:tag",
            name: "VisitantList",
            component: () => import("./pages/hodu_h/VisitantList.vue")
        },
        /**
         * 방문차량 리스트(AMANO)
         */
         {
            path: "/home/visitant/:tag/amano",
            name: "VisitantListAmano",
            component: () => import("./pages/hodu_h/VisitantListAmano.vue")
        },
        /**
         * 입주자 차량 리스트
         */
        {
            path: "/home/resident/car",
            name: "ResidentList",
            component: () => import("./pages/hodu_h/ResidentCarList.vue")
        },
        /**
         * 입주 예약 현황
         */
        {
            path: "/home/resident/request",
            name: "ResidentMoveIntoRequest",
            component: () => import("./pages/hodu_h/ResidentMoveIntoRequest.vue")
        },
        /**
         * 입주 예약 설정
         */
        {
            path: "/home/resident/request/setting",
            name: "ResidentMoveIntoRequestSetting",
            component: () => import("./pages/hodu_h/ResidentMoveIntoRequestSetting.vue")
        },
        /**
         * 입주 가입 신청
         */
         {
            path: "/home/resident/join",
            name: "ResidentJoin",
            component: () => import("./pages/hodu_h/ResidentJoin.vue")
        },
        /**
         * 안내방송 리스트
         */
        {
            path: "/home/announcement",
            name: "AnnouncementList",
            component: () => import("./pages/hodu_h/AnnouncementList.vue")
        },
        /**
         * 안내방송 생성
         */
        {
            path: "/home/announcement/create",
            name: "AnnouncementCreate",
            component: () => import("./pages/hodu_h/AnnouncementCreate.vue")
        },
        /**
         * 안내방송 수정
         */
        {
            path: "/home/announcement/modify/:uid",
            name: "AnnouncementModify",
            component: () => import("./pages/hodu_h/AnnouncementCreate.vue")
        },
        /**
         * 안내방송 상세
         */
        {
            path: "/home/announcement/:uid",
            name: "Announcement",
            component: () => import("./pages/hodu_h/Announcement.vue")
        },
        /**
         * 장비 목록
         */
        {
            path: "/home/equipment",
            name: "EquipmentList",
            component: () => import("./pages/hodu_h/EquipmentList.vue")
        },
        /**
         * 장비 등록
         */
        {
            path: "/home/equipment/create",
            name: "EquipmentCreate",
            component: () => import("./pages/hodu_h/EquipmentCreate.vue")
        },
        /**
         * 장비 수정
         */
        {
            path: "/home/equipment/modify/:uid",
            name: "EquipmentModify",
            component: () => import("./pages/hodu_h/EquipmentCreate.vue")
        },
        /**
         * 장비 상세
         */
        {
            path: "/home/equipment/:uid",
            name: "Equipment",
            component: () => import("./pages/hodu_h/Equipment.vue")
        },
        /**
         * 장비 관리 이력 등록
         */
        {
            path: "/home/equipment/:uid/management/create",
            name: "EquipmentManagementCreate",
            component: () => import("./pages/hodu_h/EquipmentManagementCreate.vue")
        },
        /**
         * 장비 관리 이력 수정
         */
         {
            path: "/home/equipment/:uid/management/modify/:seq",
            name: "EquipmentManagementModify",
            component: () => import("./pages/hodu_h/EquipmentManagementCreate.vue")
        },
        /**
         * 비품 관리 리스트
         */
        {
            path: "/home/supply",
            name: "SupplyList",
            component: () => import("./pages/hodu_h/SupplyList.vue")
        },
        /**
         * 비품 등록
         */
         {
            path: "/home/supply/create",
            name: "SupplyCreate",
            component: () => import("./pages/hodu_h/SupplyCreate.vue")
        },
        /**
         * 비품 수정
         */
        {
            path: "/home/supply/modify/:uid",
            name: "SupplyModify",
            component: () => import("./pages/hodu_h/SupplyCreate.vue")
        },
        /**
         * 비품 상세
         */
        {
            path: "/home/supply/:uid",
            name: "Supply",
            component: () => import("./pages/hodu_h/Supply.vue")
        },
        /**
         * 비품 관리 이력 등록 (입고, 출고)
         */
         {
            path: "/home/supply/:uid/management/:type/create",
            name: "SupplyManagementCreate",
            component: () => import("./pages/hodu_h/SupplyManagementCreate.vue")
        },
        /**
         * 비품 관리 이력 수정
         */
         {
            path: "/home/supply/:uid/management/modify/:seq",
            name: "SupplyManagementModify",
            component: () => import("./pages/hodu_h/SupplyManagementCreate.vue")
        },
        /**
         * 아파트 세팅
         */
        {
            path: "/home/apartment/setting/:tag",
            name: "ApartmentSetting",
            component: () => import("./pages/hodu_h/ApartmentSetting.vue")
        },
        /**
         * 입주민 데이터
         */
        {
            path: "/home/resident_data",
            name: "ResidentData",
            component: () => import("./pages/hodu_h/ResidentData.vue")
        },
        /**
         * 근태현황
         */
        {
            path: "/:scope/:scope_id/attendance",
            name: "Attendance",
            component: () => import("./pages/attendance/Attendance.vue")
        },
        /**
         * 전자결재
         */
        {
            path: "/:scope/:scope_id/approval",
            name: "Approval",
            component: () => import("./pages/approval/Approval.vue")
        },
        {
            path: "/:scope/:scope_id/approval/create",
            name: "ApprovalCreate",
            component: () => import("./pages/approval/ApprovalCreate.vue")
        },
        {
            path: "/:scope/:scope_id/approval/create/:approval_id",
            name: "ApprovalTempModify",
            component: () => import("./pages/approval/ApprovalCreate.vue")
        },
        {
            path: "/:scope/:scope_id/approval/:approval_id",
            name: "ApprovalDetail",
            component: () => import("./pages/approval/ApprovalDetail.vue")
        },
        /**
         * 조직관리
         */
        {
            path: "/:scope/:scope_id/organization/setting",
            name: "OrganizationSetting",
            component: () => import("./pages/organization/OrganizationSetting.vue")
        },
        {
            path: "/:scope/:scope_id/organization/member/setting",
            name: "OrganizationMemberSetting",
            component: () => import("./pages/organization/OrganizationMemberSetting.vue")
        },
        {
            path: "/:scope/:scope_id/organization/vacation",
            name: "OrganizationVacation",
            component: () => import("./pages/organization/OrganizationVacation.vue")
        },
        {
            path: "/:scope/:scope_id/organization/personal_record/create/:user_id",
            name: "OrganizationEmpPersonalRecordCreate",
            component: () => import("./pages/organization/OrganizationEmpPersonalRecordCreate.vue")
        },
        {
            path: "/:scope/:scope_id/organization/personal_record/:user_id",
            name: "OrganizationEmpPersonalRecordDetail",
            component: () => import("./pages/organization/OrganizationEmpPersonalRecordDetail.vue")
        },
        /**
         * 호두키즈
         */
        {
            path: "/:scope/:scope_id/kids/teacher",
            name: "KidsTeacher",
            component: () => import("./pages/kids/KidsTeacher.vue")
        },
        {
            path: "/:scope/:scope_id/kids",
            name: "KidsList",
            component: () => import("./pages/kids/KidsList.vue")
        },
        {
            path: "/:scope/:scope_id/kids/kid/:member_key",
            name: "KidsdDetail",
            component: () => import("./pages/kids/KidsDetail.vue")
        },
        {
            path: "/:scope/:scope_id/kids/attendance",
            name: "KidsAttendance",
            component: () => import("./pages/kids/KidsAttendance.vue")
        },
        {
            path: "/auth",
            name: "Auth",
            component: () => import("./pages/auth/Auth.vue")
        },
        {
            path: "/friend",
            name: "Friend",
            component: () => import("./pages/friend/Friend.vue")
        }
    ]
});

router.beforeEach((to : Route, from : Route, next : any) => {

    const is_login_app : boolean = window.location.hostname.includes("login");

    if( is_login_app ) {
        return;
    }

    if( to.path == '/login' || to.path == '/exception' || to.path == "/auth" || 
        to.path == '/find/password' || to.path == '/example' || to.path == '/his_test' || 
        to.path == '/delete_data' ) {
        next();
        return;
    }

    if( store.state.LoginInfo.isLogin == false ) {
        next('/login');
        return;
    }

    next();

}); 

export default router;