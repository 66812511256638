
import { Vue, Component, Mixins } from 'vue-property-decorator';
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');

import { t_event_file } from '@/model/event';
import { ImageCropModalInfo } from '@/store/modules/ModalInfo';

@Component({
    components: {
        
    },
})
export default class OrganizationEmpPersonalRecordCreate extends Mixins(VueHoduCommon) {

    /**
     * @ModalInfo.Action
     */
     @ModalInfo.Action doSetImageCropModalInfo ?: (params : ImageCropModalInfo) => void;

    target_user_id : number = 0;
    record : any = null;

    departments : any[] = [];
    position : any[] = [];
    work_types : any[] = [];

    profile_image : any = null;
    profile_image_file : File | null = null;

    file_drag : boolean = false;
    all_files : any = [];

    default_user_image : string = require('@/assets/images/contents/user_photo_fff.png');

    is_create : boolean = true;

    beforeMount() {
        this.target_user_id = this.$route.params.user_id ? Number(this.$route.params.user_id) : 0;
    }

    mounted() : void {
        if( isNaN(this.target_user_id) || this.target_user_id < 1 ) {
            this.movePrevPage();
            return;
        }

        this.getTotalOrganizationData();
    }

    /**
     * 종합적인 조직도 데이터 조회
     */
     async getTotalOrganizationData() : Promise<void> {
        try {
            await this.hodu_show_indicator();
            await this.getOrganizationPosition();
            await this.getOrganization();
            await this.getOrganizationWorktype();
            await this.getPersonalRecord();
            await this.hodu_hide_indicator();
            await this.datepickerInit();

        } catch(e) {
            this.hodu_show_dialog("cancel", "인사정보 조회 중 오류 발생", ['확인'], [() => { this.movePrevPage(); }]);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 부서 조회 중 오류 발생");
            }
            
            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            response.data.data.pos_info.sort((o1, o2) : number => {
                if( o1.pos_seq > o2.pos_seq ) return 1;
                else if( o1.pos_seq < o2.pos_seq ) return -1;
                return 0;
            })

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            throw e;
        }
    }
    
    /**
     * 근무 타입 조회
     */
    async getOrganizationWorktype() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/worktype`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.work_type_info ) {
                throw new Error("조직도 근무 타입 조회 중 오류 발생");
            }

            this.work_types.splice(0, this.work_types.length);
            this.work_types = this.work_types.concat(response.data.data.work_type_info);

        } catch(e) {
            throw e;
        }
    }

    async getPersonalRecord() {
        try {

            const group_id = this.scope_group_id;
            const user_id = this.target_user_id;

            // 인사정보 등록 및 수정 권한 체크
            let create_and_update_permission = this.get_group_role(this.scope_group_id) == 'ADMIN';

            if( create_and_update_permission == false ) {

                const permission_response = await this.hodu_api_call(`api/v1/groups/${group_id}/personal_record/permission`, API_METHOD.GET);

                console.log(permission_response);

                if( permission_response == null || !this.isHttpStatusSuccess(permission_response.status) || !permission_response.data || !permission_response.data.data ) {
                    throw new Error("인사정보 등록 및 수정 권한 조회중 오류발생");
                }

                const permission = permission_response.data.data.permission;

                if( permission != null && permission.length > 0 ) {
                    for( const perm of permission ) {
                        if( perm.user_id == this.user_id ) {
                            create_and_update_permission = true;
                            break;
                        } 
                    }
                }
            }

            if( create_and_update_permission == false ) {
                this.hodu_show_dialog("cancel", "인사정보 등록 수정 권한이 없습니다", ['확인'], [() => {
                    this.movePrevPage();
                }]);
                return;
            }

            // 인사정보 조회 (없으면 등록, 있으면 수정)
            const response = await this.hodu_api_call(`api/v1/groups/${group_id}/organization/emp/personal_record/${user_id}`, API_METHOD.GET, null, false);

            console.log(response);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("인사정보 조회중 오류발생");
            }

            let temp_record = response.data.data.record;
            if( response.data.data.record != null ) {
                for( const additional_info of temp_record.personal_record_data.additional_information ) {
                    switch( additional_info.type ) {
                        case "education":
                            additional_info.title = "학력";
                            break;

                        case "career":
                            additional_info.title = "경력";
                            break;

                        case "license_and_awards":
                            additional_info.title = "자격증 / 수상";
                            break;

                        case "training_courses":
                            additional_info.title = "교육이수";
                            break;
                    }
                }
            }

            this.record = JSON.parse(JSON.stringify(temp_record));
            this.is_create = (this.record == null);

            if( this.is_create == true ) {

                // 해당 유저 기본정보 조회
                const default_response = await this.hodu_api_call(`api/v1/groups/${group_id}/organization/emp/personal_record/${user_id}?is_not_allow=true`, API_METHOD.GET, null, false);

                console.log(default_response);

                if( default_response == null || !this.isHttpStatusSuccess(default_response.status) || !default_response.data || !default_response.data.data || !default_response.data.data.record ) {
                    throw new Error("인사정보 조회중 오류발생");
                }

                const default_user_info = JSON.parse(JSON.stringify(default_response.data.data.record));

                this.record = {
                    group_id : group_id,
                    user_id : user_id,
                    dept_id : default_user_info.dept_id,
                    pos_id : default_user_info.pos_id,
                    work_type_id : default_user_info.work_type_id,
                    start_date : default_user_info.start_date,
                    personal_record_data : {
                        "tel": default_user_info.tel,
                        "name": default_user_info.name,
                        "email": default_user_info.email,
                        "files": [],
                        "gender": "",
                        "address": "",
                        "birthday": "",
                        "position": "",
                        "extension_tel": "",
                        "profile_image": null,
                        "employment_type": "",
                        "additional_information": [{
                            "type" : "",
                            "title" : "",
                            "content" : "",
                            "history" : [
                                {
                                    "start" : null,
                                    "end" : null,
                                    "date" : null,
                                    "name" : "",
                                    "status" : "",
                                    "is_currently_employed" : false,
                                    "publisher" : "",
                                    "educational_institution" : "",
                                }
                            ]
                        }]
                    }
                }
            }
            else if( this.is_create == false && this.record.personal_record_data.additional_information.length < 1 ) {
                this.record.personal_record_data.additional_information.push({
                    "type" : "",
                    "title" : "",
                    "content" : "",
                    "history" : [
                        {
                            "start" : null,
                            "end" : null,
                            "date" : null,
                            "name" : "",
                            "status" : "",
                            "is_currently_employed" : false,
                            "publisher" : "",
                            "educational_institution" : "",
                        }
                    ]
                });
            }

        } catch(e) {
            throw e;
        }
    }

    async datepickerInit() {
        
        const vue = this;

        this.$nextTick(() => {
            // @ts-ignore
            $('.personal_record_datepicker, #birthday').datepicker({
                inline: false,
                showOtherMonths: true,
                selectOtherMonths: true,
                dateFormat: 'yy-mm-dd',
                monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
                dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
                yearSuffix : '.',
                blankSpace : '',
                changeYear : true,
                yearRange  : '1900:2050',
                onSelect: function (dateText, inst) {
                    
                    const id = inst.id;

                    if( id == 'birthday' ) {
                        inst.input.val(vue.hodu_date_to_format_string(dateText, 'YYYY.MM.DD'));
                        vue.record.personal_record_data.birthday = vue.hodu_date_to_format_string(dateText, 'YYYY.MM.DD');
                        return;
                    }

                    if( id == 'joinDateText' ) {
                        inst.input.val(vue.hodu_date_to_format_string(dateText, 'YYYY.MM.DD'));
                        vue.record.start_date = vue.hodu_date_to_format_string(dateText, 'YYYYMMDD');
                        return;
                    }

                    // 부가정보 날짜 선택들
                    inst.input.val(vue.hodu_date_to_format_string(dateText, 'YYYY.MM'));

                    if( id.indexOf("edcDateTextStart") == 0 ) {
                        const replace = id.replace("edcDateTextStart_", "");
                        const index_array = replace.split('_');

                        const approval_info_index = index_array[0];
                        const history_index = index_array[1];

                        const additional_information = vue.record.personal_record_data.additional_information[approval_info_index];
                        const history = additional_information.history[history_index];

                        if( additional_information.type == 'license_and_awards' ) {
                            history.date = vue.hodu_date_to_format_string(dateText, 'YYYY.MM');
                        }
                        else {
                            history.start = vue.hodu_date_to_format_string(dateText, 'YYYY.MM');
                        }

                        return;
                    }

                    if( id.indexOf("edcDateTextEnd") == 0 ) {
                        const replace = id.replace("edcDateTextEnd_", "");
                        const index_array = replace.split('_');

                        const approval_info_index = index_array[0];
                        const history_index = index_array[1];

                        const additional_information = vue.record.personal_record_data.additional_information[approval_info_index];
                        const history = additional_information.history[history_index];

                        history.end = vue.hodu_date_to_format_string(dateText, 'YYYY.MM');

                        return;
                    }

                },
            });
        })
    }

    /**
     * 프로필 이미지 변경
     */
     async profileImageChange(event) : Promise<void> {
        const vue = this;
        const files : File[] = event.target.files;

        await this.hodu_show_indicator();
        await this.fileReaderPromise(files[0])
            .then(async(pe_fr : any) => {
                
                // onloadend로 들어온 경우
                if( pe_fr == null ) {
                    $('#changePersonalRecordPic').val("");
                    return;
                }

                if( pe_fr.target == null || pe_fr.target.result == null ){
                    return;
                }

                let base64url : string = "";

                if( pe_fr.target.result instanceof ArrayBuffer ){
                    const arrayBuffer : Uint8Array = new Uint8Array(pe_fr.target.result);
                    const url : string = String.fromCharCode.apply(null, Array.from(arrayBuffer));
                    base64url = decodeURIComponent(url);
                } else {
                    base64url = pe_fr.target.result;
                }

                const blob : Blob = await vue.hodu_image_resize(base64url, window.innerWidth, window.innerHeight -76 , 1);
                const url :string = URL.createObjectURL(blob);

                if( this.doSetImageCropModalInfo == null ) { return; }

                this.doSetImageCropModalInfo({
                    show_modal : true,
                    crop_image_ratio : (1/1), // 1:1
                    image_url : url,
                    cancel_function : async() => {
                        $('#changePersonalRecordPic').val("");
                    },
                    confirm_function : async(cropped_url : string) => { 
                        try {
                            await this.hodu_show_indicator();

                            const resize_blob : Blob = await vue.hodu_image_resize(cropped_url);
                            
                            // TODO IE11 , SAFARI 13 이하 , ios safari 13.2 이하는 new File 사용불가
                            let resize_file : File = files[0];
                            try{
                                resize_file = await this.hodu_blob_to_file(resize_blob, files[0].name);
                            }catch(e){
                                try {
                                    (resize_blob as any).lastModifiedDate = new Date();
                                    (resize_blob as any).name = files[0].name;
                                    resize_file = (resize_blob as any);
                                } catch(e) {
                                    this.hodu_error_process(e, false, false, true);
                                }
                            }

                            this.profile_image = JSON.parse(JSON.stringify({
                                name: resize_file.name, // 원본 파일명
                                mimeType: resize_file.type, // MIME TYPE
                                url: URL.createObjectURL(resize_blob), // 파일 경로
                                size: resize_file.size, // 파일 크기
                                date: new Date() // 저장 날짜
                            }));

                            this.profile_image_file = resize_file;
                            
                        } catch(e) {
                            this.hodu_error_process(e, false, false, true)
                        } finally {
                            this.hodu_hide_indicator();
                            $('#changePersonalRecordPic').val("");
                        }
                    }
                });

            })
            .catch((e) => {
                console.log('filereader promise error',e);
            })
            .finally(async() => {
                await this.hodu_hide_indicator();
            });

        
    }

    /**
     * 파일리더 promise
     */
    fileReaderPromise(file : File) : Promise<any> {
        return new Promise((resolve, reject) => {
            const fileReader : FileReader = new FileReader();
            fileReader.onload = (fr) => resolve(fr);
            fileReader.onerror = () => reject();
            fileReader.readAsDataURL(file);
        });
    }

    /**
     * yyyymmdd 데이터를 Date로 바꿔서 반환 해줌
     */
     yyyymmddToDate(yyyymmdd : string) : Date {
        return new Date([yyyymmdd.substring(0,4), yyyymmdd.substring(4,6), yyyymmdd.substring(6,8)].join('-'));
    }

    /**
     * 파일을 드래그해서 이미지 영역에 올려놨을때
     */
     fileDragOver(event) : void {
        event.dataTransfer.dropEffect = 'copy';
        this.file_drag = true;
    }

    /**
     * 파일을 드래그해서 이미지 영역에서 벗어났을때
     */
    fileDragLeave() : void {
        this.file_drag = false;
    }

    /**
     * 파일을 드래그 한 후 이미지 영역에 떨어뜨린 경우
     */
    fileDrop(event) : void {
        this.file_drag = false;
        this.addFile(event.dataTransfer.files);
    }

    /**
     * 파일 추가 - +버튼 눌러서 추가
     */
     addFileNormal(event) : void {
        const vue = this;
        const files : File[] = event.target.files;
        this.addFile(files);
    }

    /**
     * 파일 객체 추가
     */
     addFile(files : File[]) : void {
        
        const file_count : number = files.length;
        const event_files : t_event_file[] = [];
        
        let max_size : number = this.DEFAULT_FILE_MAX_SIZE;
        let max_size_text : string = this.DEFAULT_FILE_MAX_SIZE_TEXT;

        // 파일 용량 체크 후 t_event_file로 만들어 push
        for( let i = 0; i < file_count; i++ ) {
            if( files[i].size > max_size ) {
                alert(`${max_size_text} 이하의 파일만 업로드 가능 합니다`);
                $('#hrFile').val("");
                return;
            }

            // 확장자가 없는 파일
            if( files[i].name.lastIndexOf('.') == -1 ) {
                alert("확장자가 있는 파일만 업로드 가능 합니다");
                $('#hrFile').val("");
                return;
            } 
            
            // 확장자 제한 확인
            if( this.file_extension_forbidden.indexOf(files[i].name.substring(files[i].name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                alert(`${ files[i].name.substring(files[i].name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`);
                $('#hrFile').val("");
                return;
            }
            
            event_files.push({
                name: files[i].name,     // 원본 파일명
                mimeType: files[i].type, // MIME TYPE
                url: "",                 // 파일 경로
                size: files[i].size,     // 파일 크기
                date: new Date()         // 저장 날짜
            })

            // 업로드용 파일 객체 담기
            this.all_files.push({
                index : (this.record.personal_record_data.files.length + i), 
                file : files[i]
            });
        }

        // 배열 합치기
        this.record.personal_record_data.files = this.record.personal_record_data.files.concat(event_files);

        // 파일 input 비우기
        $('#hrFile').val("");
    }

    /**
     * 파일 삭제
     */
     deleteFile(file : t_event_file) : void {
        
        const file_index : number = this.record.personal_record_data.files.indexOf(file);

        if( file_index == -1 ){
            return;
        }

        this.record.personal_record_data.files.splice(file_index, 1);

        // 새로 올리는 파일을 검사해서 같이 삭제한다
        const files_count : number = this.all_files.length;
        for( let i = (files_count - 1); i >= 0; i--) {
            
            // 삭제하는 인덱스보다 큰 객체는 index를 1 줄인다
            if( this.all_files[i].index > file_index ) {
                this.all_files[i].index--;
            }

            // 삭제할 인덱스가 보이면 삭제후 나간다
            else if( this.all_files[i].index == file_index ) {
                this.all_files.splice(i, 1);
                break;
            }
        }
    }
    
    addAdditionalInfo() {
        if( this.record == null ) return;

        this.record.personal_record_data.additional_information.push({
            "type" : "",
            "title" : "",
            "content" : "",
            "history" : [
                {
                    "start" : null,
                    "end" : null,
                    "date" : null,
                    "name" : "",
                    "status" : "",
                    "is_currently_employed" : false,
                    "publisher" : "",
                    "educational_institution" : "",
                }
            ]
        });

        // 부가정보 추가된 쪽으로 스크롤
        this.$nextTick(() => {
            // .addListTitle 높이 + top margin = 85
            // 정보 추가시 추가된 기본형 .moreThanOp 높이 + top margin = 120
            // TODO 파일의 위치와 높이 등의 퍼블 변경시 이쪽도 바꿔줘야함
            const a = $('.hrInforFile').position().top - (85 + 120);
            const scroll_container = document.getElementById('hr_detail_create');
            scroll_container?.scrollTo(0, a);
        });
    }

    addAdditionalInfoHistory(event, additional_info) {
        additional_info.history.push({
            "start" : null,
            "end" : null,
            "date" : null,
            "name" : "",
            "status" : "",
            "is_currently_employed" : false,
            "publisher" : "",
            "educational_institution" : "",
        });

        this.datepickerInit();
    }

    deleteAdditionalInfoHistory(event, additional_info, history) {
        const index = additional_info.history.indexOf(history);
        additional_info.history.splice(index, 1);
    }

    getHistoryStart(additional_info, history) {

        if( history.start != null ) {
            return this.hodu_date_to_format_string(history.start, 'YYYY.MM');
        }

        if( history.date != null ) {
            return this.hodu_date_to_format_string(history.date, 'YYYY.MM');
        }
        
        switch( additional_info.type ) {
            case "education":
                return "입학년월";
            case "career":
                return "입사년월";
            case "license_and_awards":
                return "취득년월";
            case "training_courses":
                return "시작년월";
        }

        return "";
    }

    getHistoryEnd(additional_info, history) {
        if( history.end != null ) {
            return this.hodu_date_to_format_string(history.end, 'YYYY.MM');
        }
        
        switch( additional_info.type ) {
            case "education":
                return "졸업년월";
            case "career":
                return "퇴사년월";
            case "training_courses":
                return "종료년월";
        }

        return "";
    }

    getHistoryNamePlaceholder(additional_info) {
        switch( additional_info.type ) {
            case "education":
                return "학교명";
            case "career":
                return "회사명";
            case "license_and_awards":
                return "자격증명 또는 수상명";
            case "training_courses":
                return "교육명";
        }

        return "";
    }

    getHistorySecondNamePlaceholder(additional_info) {
        switch( additional_info.type ) {
            case "license_and_awards":
                return "발행처";
            case "training_courses":
                return "교육기관";
        }

        return "";
    }

    inputAdditionalInfo(event, additional_info) {

        const index = this.record.personal_record_data.additional_information.indexOf(additional_info);
        const value = event.target.value;

        console.log(event);
        console.log(value);

        if( additional_info.type != null && additional_info.type.length > 0 && additional_info.type != 'etc' ) {

            if( value == additional_info.title ) {
                return;
            }

            switch( value ) {
                case "학력":
                    additional_info.type = "education";
                    break;
                case "경력":
                    additional_info.type = "career";
                    break;
                case "자격증 / 수상":
                    additional_info.type = "license_and_awards";
                    break;
                case "교육이수":
                    additional_info.type = "training_courses";
                    break;
                case "":
                    additional_info.type = "";
                    break;
                default:
                    additional_info.type = "etc";
                    break;
            }

            // $(event.target).blur();
            // this.hodu_show_dialog("alert", "기본폼 제목 변경시 입력한 내용이 사라집니다\n진행하시겠습니까?", ['아니오', '예'], [
            //     () => { 
            //         $(event.target).val(additional_info.title);
            //     },
            //     () => {
                    
            //     }
            // ]);

            let new_info = JSON.parse(JSON.stringify({
                "type" : value.length < 1 ? "" : "etc",
                "title" : value,
                "content" : "",
                "history" : [
                    {
                        "start" : null,
                        "end" : null,
                        "date" : null,
                        "name" : "",
                        "status" : "",
                        "is_currently_employed" : false,
                        "publisher" : "",
                        "educational_institution" : "",
                    }
                ]
            }));

            this.record.personal_record_data.additional_information.splice(index, 1, new_info);
            $(event.target).val(value);
            this.datepickerInit();
            return;
        }

        additional_info.title = value;
        $(event.target).val(additional_info.title);

        switch( additional_info.title ) {
            case "학력":
                additional_info.type = "education";
                break;
            case "경력":
                additional_info.type = "career";
                break;
            case "자격증 / 수상":
                additional_info.type = "license_and_awards";
                break;
            case "교육이수":
                additional_info.type = "training_courses";
                break;
            case "":
                additional_info.type = "";
                break;
            default:
                additional_info.type = "etc";
                break;
        }

        if( additional_info.history == null ) {
            additional_info.history = [
                {
                    "start" : null,
                    "end" : null,
                    "date" : null,
                    "name" : "",
                    "status" : "",
                    "is_currently_employed" : false,
                    "publisher" : "",
                    "educational_institution" : "",
                }
            ];
        }

        const new_info = JSON.parse(JSON.stringify(additional_info));
        this.record.personal_record_data.additional_information.splice(index, 1, new_info);

        this.datepickerInit();
    }

    deleteAdditionalInformation(event, additional_info) {
        if( this.record == null ) return;
        const index = this.record.personal_record_data.additional_information.indexOf(additional_info);
        this.record.personal_record_data.additional_information.splice(index, 1);
    }

    changeCurrentlyEmployed(event, additional_info, history) {
        const checked = event.target.checked;

        const history_index = additional_info.history.indexOf(history);

        if( checked == true ) {

            for( const his of additional_info.history ) {

                const copy_history = JSON.parse(JSON.stringify(his));
                const copy_history_index = additional_info.history.indexOf(his);
                
                if( copy_history_index == history_index ) {
                    copy_history.is_currently_employed = true;
                }
                else {
                    copy_history.is_currently_employed = false;
                }

                additional_info.history.splice(copy_history_index, 1, copy_history);
            }
        }
    }
        
    async save() {

        // 필수값 체크
        
        if( this.record == null ) return;

        if( this.record.personal_record_data.name.trim().length < 1 ) {
            this.hodu_show_dialog('alert', '이름을 입력해주세요', ['확인']);
            return;
        }
        else {
            this.record.personal_record_data.name = this.record.personal_record_data.name.trim();
        }

        if( this.record.personal_record_data.tel.trim().length < 1 ) {
            this.hodu_show_dialog('alert', '전화번호를 입력해주세요', ['확인']);
            return;
        }
        else {
            this.record.personal_record_data.tel = this.record.personal_record_data.tel.trim();
        }

        if( this.record.personal_record_data.gender.trim().length < 1 ) {
            this.hodu_show_dialog('alert', '성별을 입력해주세요', ['확인']);
            return;
        }
        else {
            this.record.personal_record_data.gender = this.record.personal_record_data.gender.trim();
        }

        if( this.record.personal_record_data.birthday.trim().length < 1 ) {
            this.hodu_show_dialog('alert', '생년월일을 입력해주세요', ['확인']);
            return;
        }
        else {
            this.record.personal_record_data.birthday = this.record.personal_record_data.birthday.trim();
        }

        if( this.record.personal_record_data.address.trim().length < 1 ) {
            this.hodu_show_dialog('alert', '주소를 입력해주세요', ['확인']);
            return;
        }
        else {
            this.record.personal_record_data.address = this.record.personal_record_data.address.trim();
        }

        if( this.record.personal_record_data.additional_information.length == 1 && this.record.personal_record_data.additional_information[0].type == "" ) {
            this.record.personal_record_data.additional_information = [];
        } 

        if( this.record.personal_record_data.additional_information.length > 1 ) {
            this.record.personal_record_data.additional_information = this.record.personal_record_data.additional_information.filter(info => info.type != "");
        }

        for( const additional_info of this.record.personal_record_data.additional_information ) {
            // education : start, end, name, status 처리
            if( additional_info.type == 'education' ) {
                for( const history of additional_info.history ) {
                    
                    if( history.start == null ) {
                        this.hodu_show_dialog('alert', '학력 입학년월을 입력해주세요', ['확인']);
                        return;
                    }

                    if( history.end == null ) {
                        this.hodu_show_dialog('alert', '학력 졸업년월을 입력해주세요', ['확인']);
                        return;
                    }

                    if( Number(history.start) > Number(history.end) ) {
                        this.hodu_show_dialog('alert', '학력 졸업년월이 입학년월 보다 빠릅니다', ['확인']);
                        return;
                    }

                    if( history.name.trim().length < 1 ) {
                        this.hodu_show_dialog('alert', '학력 학교명을 입력해주세요', ['확인']);
                        return;
                    }
                    else {
                        history.name = history.name.trim();
                    }

                    if( history.status == null || history.status == "" ) {
                        this.hodu_show_dialog('alert', '학력 졸업상태를 입력해주세요', ['확인']);
                        return;
                    }

                }
            }

            // career :  start, end, name, is_currently_employed 처리
            else if( additional_info.type == 'career' ) {
                for( const history of additional_info.history ) {
                    
                    if( history.start == null ) {
                        this.hodu_show_dialog('alert', '경력 입사년월을 입력해주세요', ['확인']);
                        return;
                    }

                    if( history.end == null && history.is_currently_employed == false ) {
                        this.hodu_show_dialog('alert', '경력 퇴사년월을 입력해주세요', ['확인']);
                        return;
                    }

                    if( history.is_currently_employed == false && Number(history.start) > Number(history.end) ) {
                        this.hodu_show_dialog('alert', '경력 퇴사년월이 입사년월 보다 빠릅니다', ['확인']);
                        return;
                    }

                    if( history.name.trim().length < 1 ) {
                        this.hodu_show_dialog('alert', '경력 회사명을 입력해주세요', ['확인']);
                        return;
                    }
                    else {
                        history.name = history.name.trim();
                    }

                }
            }

            // license_and_awards : start, name, publisher 처리
            else if( additional_info.type == 'license_and_awards' ) {
                for( const history of additional_info.history ) {
                    
                    if( history.date == null ) {
                        this.hodu_show_dialog('alert', '자격증 / 수상 취득년월을 입력해주세요', ['확인']);
                        return;
                    }

                    if( history.name.trim().length < 1 ) {
                        this.hodu_show_dialog('alert', '자격증 / 수상 자격증명 또는 수상명을 입력해주세요', ['확인']);
                        return;
                    }
                    else {
                        history.name = history.name.trim();
                    }

                    if( history.publisher.trim().length < 1 ) {
                        this.hodu_show_dialog('alert', '자격증 / 수상 발행처를 입력해주세요', ['확인']);
                        return;
                    }
                    else {
                        history.publisher = history.publisher.trim();
                    }

                }
            }

            // training_courses : start, end, name, educational_institution 처리
            else if( additional_info.type == 'training_courses' ) {
                for( const history of additional_info.history ) {

                    if( history.start == null ) {
                        this.hodu_show_dialog('alert', '교육이수 시작년월을 입력해주세요', ['확인']);
                        return;
                    }

                    if( history.end == null ) {
                        this.hodu_show_dialog('alert', '교육이수 종료년월 입력해주세요', ['확인']);
                        return;
                    }

                    if( Number(history.start) > Number(history.end) ) {
                        this.hodu_show_dialog('alert', '교육이수 종료년월이 시작년월 보다 빠릅니다', ['확인']);
                        return;
                    }

                    if( history.name.trim().length < 1 ) {
                        this.hodu_show_dialog('alert', '교육이수 교육명을 입력해주세요', ['확인']);
                        return;
                    }
                    else {
                        history.name = history.name.trim();
                    }

                    if( history.educational_institution.trim().length < 1 ) {
                        this.hodu_show_dialog('alert', '교육이수 교육기관을 입력해주세요', ['확인']);
                        return;
                    }
                    else {
                        history.educational_institution = history.educational_institution.trim();
                    }

                }
            }

            // etc : title, content 처리
            else if( additional_info.type == 'etc' ) {
                if( additional_info.title.trim().length < 1 ) {
                    this.hodu_show_dialog('alert', '부가정보 제목을 입력해주세요', ['확인']);
                    return;
                }
                else {
                    additional_info.title = additional_info.title.trim();
                }

                if( additional_info.content.trim().length < 1 ) {
                    this.hodu_show_dialog('alert', '부가정보 내용을 입력해주세요', ['확인']);
                    return;
                }
                else {
                    additional_info.content = additional_info.content.trim();
                }
            }
        }

        // 프로필 이미지 임시파일 처리
        if( this.profile_image != null && this.profile_image_file != null ) {

            const form_data : FormData = new FormData();
        
            // FormData 내용 생성
            form_data.append('file', this.profile_image_file);
            
            try {
                const response = await this.hodu_temp_upload(form_data);
                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                    throw new Error("이미지 임시 업로드 실패");
                }

                const temp_files : t_event_file[] = response.data.data.temp_files;

                if( temp_files.length > 0 ) {
                    this.record.personal_record_data.profile_image = JSON.parse(JSON.stringify(temp_files[0]));
                }

            } catch(e) {
                this.hodu_error_process(e, true, false);
                return;
            }
        }

        // 첨부파일 임시파일 처리
        if( this.all_files.length > 0 ) {

            // await 파일 TEMP 업로드
            const form_data : FormData = new FormData();
            
            // FormData 내용 생성
            const all_files_count : number = this.all_files.length;
            for( let i = 0; i < all_files_count; i++ ) {
                form_data.append('file', this.all_files[i].file);
            }

            try {
                const response = await this.hodu_temp_upload(form_data);
                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                    throw new Error("파일 임시 업로드 실패");
                }

                const temp_files : t_event_file[] = response.data.data.temp_files;

                // 임시파일 데이터로 객체 대체하기
                const all_files_count : number = this.all_files.length;
                for( let i = 0; i < all_files_count; i++ ) {
                    this.record.personal_record_data.files.splice(this.all_files[i].index, 1, temp_files[i]);    
                }

            } catch(e) {
                this.hodu_error_process(e, true, false);
                return;
            }

        }

        // 등록
        if( this.is_create == true ) {
            try {

                const group_id = this.scope_group_id;
                const user_id = this.target_user_id;

                // API 처리
                const response = await this.hodu_api_call(`api/v1/groups/${group_id}/organization/emp/personal_record/${user_id}`, API_METHOD.POST, this.record, false);

                console.log(response);

                if( response == null || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("인사정보 수정중 오류발생");
                }

                // 뒤로가기 + 상세정보로 가기
                this.movePrevPage();

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "인사정보 수정 중 오류 발생", ['확인']);
            }
        }

        // 수정
        else {
            try {
                // API 처리
                const group_id = this.scope_group_id;
                const user_id = this.target_user_id;

                // API 처리
                const response = await this.hodu_api_call(`api/v1/groups/${group_id}/organization/emp/personal_record/${user_id}`, API_METHOD.PUT, this.record, false);

                console.log(response);

                if( response == null || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("인사정보 수정중 오류발생");
                }

                // 뒤로가기
                this.movePrevPage();

            } catch(e) {
                this.hodu_error_process(e, false, false, true);
                this.hodu_show_dialog("cancel", "인사정보 수정 중 오류 발생", ['확인']);
            }
        }
    }

    userImageError(event) : void {
        $(event.target).parent().find('p.img_bring').css('background-image', require('@/assets/images/contents/user_photo_fff.png'))
    }

}
