import Vue from "vue";
import Vuex from "vuex";
import * as LoginInfo        from "./modules/LoginInfo";
import * as CalendarInfo     from "./modules/CalendarInfo";
import * as EventInfo        from "./modules/EventInfo";
import * as GroupInfo        from "./modules/GroupInfo";
import * as TodoInfo         from "./modules/TodoInfo";
import * as MenuInfo         from "./modules/MenuInfo";
import * as ModalInfo        from "./modules/ModalInfo";
import * as EtcInfo          from "./modules/EtcInfo";
import * as WorkInfo         from "./modules/WorkInfo";
import * as HoduDocInfo      from "./modules/HoduDocInfo";
import * as PopupInfo        from "./modules/PopupInfo";
import * as AttendanceInfo   from "./modules/AttendanceInfo";
import * as ApprovalInfo     from "./modules/ApprovalInfo";
import * as OrganizationInfo from "./modules/OrganizationInfo";
import * as GalleryInfo      from "./modules/GalleryInfo";
import { hodu_local_storage, LocalStorageInfo, local_storage_info } from '@/lib/HoduLocalStorage';

Vue.use(Vuex);

const store = new Vuex.Store<any>({
  modules:{
    LoginInfo, CalendarInfo, EventInfo, 
    GroupInfo, TodoInfo, MenuInfo, 
    ModalInfo, EtcInfo, WorkInfo, 
    HoduDocInfo, PopupInfo, AttendanceInfo, 
    ApprovalInfo, OrganizationInfo, GalleryInfo,
  }

});

export default store;

if(sessionStorage !=null){

    try {
        const session_token = sessionStorage.getItem("session_token");
        
        /**
         * store.dispatch = 동기식(actions) -> 내부 로직이 비동기식을 실행하면 안됨
         */
        // 로그인 정보
        const login_info_json : string | null = sessionStorage.getItem("login_info");
        if( session_token != null && session_token.length > 0 && login_info_json != null && login_info_json.length > 0 ) {
            store.dispatch("LoginInfo/doLogin", JSON.parse(login_info_json));
        }

        // 그룹 정보
        const group_info_json : string | null = sessionStorage.getItem("group_info");
        if( session_token != null && session_token.length > 0 && group_info_json != null && group_info_json.length > 0 ) {
            store.dispatch("GroupInfo/doReplaceGroupInfoState", JSON.parse(group_info_json));
        }

        // 그룹 파일함 선택된 폴더 유지
        const selected_dir_uuid : string | null = sessionStorage.getItem("selected_dir_uuid");
        if( session_token != null && session_token.length > 0 && selected_dir_uuid != null && selected_dir_uuid.length > 0 ) {
            store.dispatch("GroupInfo/doSelectedDirUuid", selected_dir_uuid);
        }

        // 달력 정보
        const calendar_info_json : string | null = sessionStorage.getItem("calendar_info");
        if( session_token != null && session_token.length > 0 && calendar_info_json != null && calendar_info_json.length > 0 ) {
            store.dispatch("CalendarInfo/doSetCalendarInfo", JSON.parse(calendar_info_json));
        }

        // 호두닥 정보
        const hodu_doc_info_json : string | null = sessionStorage.getItem("hodu_doc_info");
        if( session_token != null && session_token.length > 0 && hodu_doc_info_json != null && hodu_doc_info_json.length > 0 ) {
            store.dispatch("HoduDocInfo/doSetHoduDocInfo", JSON.parse(hodu_doc_info_json));
        }

        // 근태관리 달력 정보
        const attendance_info : string | null = sessionStorage.getItem("attendance_info");
        if( session_token != null && session_token.length > 0 && attendance_info != null && attendance_info.length > 0 ) {
            store.dispatch("AttendanceInfo/doSetAttendanceInfo", JSON.parse(attendance_info));
        }

        // 전자결재 정보 (기안서/휴가원 생성시 새로고침 하면 기본 정보 유지되도록)
        const approval_info : string | null = sessionStorage.getItem("approval_info");
        if( session_token != null && session_token.length > 0 && approval_info != null && approval_info.length > 0 ) {
            store.dispatch("ApprovalInfo/doSetApproval", JSON.parse(approval_info));
        }

        // 전자결재 검색 정보 (전자결재 목록으로 돌아오면 정보 유지되도록)
        const approval_filter : string | null = sessionStorage.getItem("approval_filter");
        if( session_token != null && session_token.length > 0 && approval_filter != null && approval_filter.length > 0 ) {
            store.dispatch("ApprovalInfo/doSetFilter", JSON.parse(approval_filter));
        }

        // 전자결재 상신함 검색 정보 (전자결재 목록으로 돌아오면 정보 유지되도록)
        const approval_draft_filter : string | null = sessionStorage.getItem("approval_draft_filter");
        if( session_token != null && session_token.length > 0 && approval_draft_filter != null && approval_draft_filter.length > 0 ) {
            store.dispatch("ApprovalInfo/doSetDraftFilter", JSON.parse(approval_draft_filter));
        }

        // 전자결재 수신함 검색 정보 (전자결재 목록으로 돌아오면 정보 유지되도록)
        const approval_receive_filter : string | null = sessionStorage.getItem("approval_receive_filter");
        if( session_token != null && session_token.length > 0 && approval_receive_filter != null && approval_receive_filter.length > 0 ) {
            store.dispatch("ApprovalInfo/doSetReceiveFilter", JSON.parse(approval_receive_filter));
        }

        // 전자결재 최초 정보 조회 완료 여부 (전자결재 목록으로 돌아오면 정보 유지되도록)
        const approval_is_first_init_complete : string | null = sessionStorage.getItem("approval_is_first_init_complete");
        if( session_token != null && session_token.length > 0 && approval_is_first_init_complete != null && approval_is_first_init_complete.length > 0 ) {
            store.dispatch("ApprovalInfo/doSetIsFirstInitComplete", Boolean(approval_is_first_init_complete));
        }

        // 전자결재 선택된 탭 정보
        const approval_tab_info : string | null = sessionStorage.getItem("approval_tab_info");
        if( session_token != null && session_token.length > 0 && approval_tab_info != null && approval_tab_info.length > 0 ) {
            store.dispatch("ApprovalInfo/doSetTabInfo", JSON.parse(approval_tab_info));
        }

        const organization_member_setting_tab_info : string | null = sessionStorage.getItem("organization_member_setting_tab_info");
        if( session_token != null && session_token.length > 0 && organization_member_setting_tab_info != null && organization_member_setting_tab_info.length > 0 ) {
            store.dispatch("OrganizationInfo/doSetOrganizationMemberSelectedTab", JSON.parse(organization_member_setting_tab_info).selected_member_tab);
        }
        
        // 조직관리 선택된 탭 정보
        const organization_setting_tab_info : string | null = sessionStorage.getItem("organization_setting_tab_info");
        if( session_token != null && session_token.length > 0 && organization_setting_tab_info != null && organization_setting_tab_info.length > 0 ) {
            store.dispatch("OrganizationInfo/doSetOrganizationSelectedTab", JSON.parse(organization_setting_tab_info).selected_tab);
        }

        // 휴가현황 선택된 탭 정보
        const organization_vacation_tab_info : string | null = sessionStorage.getItem("organization_vacation_tab_info");
        if( session_token != null && session_token.length > 0 && organization_vacation_tab_info != null && organization_vacation_tab_info.length > 0 ) {
            store.dispatch("OrganizationInfo/doSetOrganizationSelectedVacationTab", JSON.parse(organization_vacation_tab_info).selected_vacation_tab);
        }

        // 갤러리 검색 정보
        const gallery_info : string | null = sessionStorage.getItem("gallery_info");
        if( session_token != null && session_token.length > 0 && gallery_info != null && gallery_info.length > 0 ) {
            store.dispatch("GalleryInfo/doSetGalleryInfo", JSON.parse(gallery_info));
        }
        
        // 로컬스토리지 정보 복구
        const local_info_string = hodu_local_storage.getItem(`${store.state.LoginInfo.user_id}`);
        if( local_info_string != null && local_info_string.length > 0 ) {
            const local_info : LocalStorageInfo = JSON.parse(local_info_string);
            local_storage_info.schedule_search_config = JSON.parse(JSON.stringify(local_info.filter_info));
            local_storage_info.last_event_created_scope = JSON.parse(JSON.stringify(local_info.filter_info));
            local_storage_info.filter_info = JSON.parse(JSON.stringify(local_info.filter_info));    
        }
        
        /**
         * commit - 비동기식(mutations)
         */
        // 일정 정보
        const event_info_json : string | null = sessionStorage.getItem("event_info");
        if( session_token != null && session_token.length > 0 && event_info_json != null && event_info_json.length > 0 ) {
            store.commit("EventInfo/setEventInfo", JSON.parse(event_info_json));
        }

        // 일정 CRUD 타입
        const event_crud_type_json : string | null = sessionStorage.getItem("event_crud_type");
        if( session_token != null && session_token.length > 0 && event_crud_type_json != null && event_crud_type_json.length > 0 ) {
            store.commit("EventInfo/setEventCrudType", JSON.parse(event_crud_type_json));
        }

        // 반복일정의 첫번째 일정인지 여부
        const is_first_repeat_event_json : string | null = sessionStorage.getItem("is_first_repeat_event");
        if( session_token != null && session_token.length > 0 && is_first_repeat_event_json != null && is_first_repeat_event_json.length > 0 ) {
            store.commit("EventInfo/setIsFirstRepeatEvent", JSON.parse(is_first_repeat_event_json));
        }

        // 반복 일정의 원본 Date
        const event_original_date_json : string | null = sessionStorage.getItem("event_original_date");
        if( session_token != null && session_token.length > 0 && event_original_date_json != null && event_original_date_json.length > 0 ) {
            store.commit("EventInfo/setEventOriginalDate", JSON.parse(event_original_date_json));
        }

        // 일정 간편 작성에서 옮겨졌는지 여부
        const is_moved_by_short_create_json : string | null = sessionStorage.getItem("is_moved_by_short_create");
        if( session_token != null && session_token.length > 0 && is_moved_by_short_create_json != null && is_moved_by_short_create_json.length > 0 ) {
            store.commit("EventInfo/setIsMovedByShortCreate", JSON.parse(is_moved_by_short_create_json));
        }

        // 업무 정보
        const work_info_json : string | null = sessionStorage.getItem("work_info");
        if( session_token != null && session_token.length > 0 && work_info_json != null && work_info_json.length > 0 ) {
            store.commit("WorkInfo/setWorkInfo", JSON.parse(work_info_json));
        }

        // 기타 정보
        const etc_info_json : string | null = sessionStorage.getItem("etc_info");
        if( session_token != null && session_token.length > 0 && etc_info_json != null && etc_info_json.length > 0 ) {
            store.commit("EtcInfo/replaceEtcInfoState", JSON.parse(etc_info_json));
        }
        
        /**
         * example_string 유지 시키기
         */
        else if ( etc_info_json != null && etc_info_json.length > 0 ) {
            store.commit("EtcInfo/setExampleString", JSON.parse(etc_info_json).example_string);
        }

    } catch(e) {
        console.log(e);
    }
}
