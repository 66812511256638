
import { Vue, Component, Mixins } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon from '@/mixin/VueHoduCommon';

import { namespace } from 'vuex-class';
const OrganizationInfo = namespace('OrganizationInfo');

import GroupMemberComp from '@/components/GroupMemberComp.vue';
import OrganizationRankSetting from '@/components/organization/OrganizationRankSetting.vue';
import OrganizationApprovalSetting from '@/components/organization/OrganizationApprovalSetting.vue';
import OrganizationEmpOffSetting from '@/components/organization/OrganizationEmpOffSetting.vue';
import OrganizationQuitterSetting from '@/components/organization/OrganizationQuitterSetting.vue';

import { organization_enum } from '@/model/organization';

import { ResizeObserver } from 'vue-resize';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        GroupMemberComp, OrganizationRankSetting, OrganizationApprovalSetting, 
        OrganizationEmpOffSetting, OrganizationQuitterSetting,
        ResizeObserver
    },
})
export default class OrganizationMemberSetting extends Mixins(VueHoduCommon) {

    organization_enum : any = organization_enum;

    event_bus : Vue = new Vue();

    /**
     * @OrganizationInfo.State
     */
    @OrganizationInfo.State selected_member_tab !: organization_enum.ORGANIZATION_SETTING_TAB;

    /**
     * @OrganizationInfo.Action
     */
    @OrganizationInfo.Action doSetOrganizationMemberSelectedTab ?: (params : organization_enum.ORGANIZATION_SETTING_TAB) => void;

    is_close_position : boolean = false;

    mounted() : void {

    }

    /**
     * 탭 선택
     */
    selectTab(tab : organization_enum.ORGANIZATION_SETTING_TAB) : void {
        this.doSetOrganizationMemberSelectedTab?.(tab);
    }

    /**
     * 직원 기본 설정 탭
     */
    isMember() : boolean {
        return this.selected_member_tab == organization_enum.ORGANIZATION_SETTING_TAB.MEMBER;
    }

    /**
     * 직원 기본 설정 탭
     */
    isRank() : boolean {
        return this.selected_member_tab == organization_enum.ORGANIZATION_SETTING_TAB.RANK;
    }

    /**
     * 직원 결재 설정 탭
     */
    isApproval() : boolean {
        return this.selected_member_tab == organization_enum.ORGANIZATION_SETTING_TAB.APPROVAL;
    }

    /**
     * 직원 연차 설정 탭
     */
    isEmpOff() : boolean {
        return this.selected_member_tab == organization_enum.ORGANIZATION_SETTING_TAB.EMP_OFF;
    }

    /**
     * 직원 퇴사자 탭
     */
    isQuitter() : boolean {
        return this.selected_member_tab == organization_enum.ORGANIZATION_SETTING_TAB.QUITTER;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        if( this.isMember() == true ) {
            this.event_bus.$emit('OrganizationMemberResize');
            return;
        }

        else if( this.isRank() == true ) {
            this.event_bus.$emit('OrganizationRankSettingResize');
            return;
        }

        else if( this.isApproval() == true ) {
            this.event_bus.$emit('OrganizationApprovalSettingResize');
        }

        else if( this.isEmpOff() == true ) {
            this.event_bus.$emit('OrganizationEmpOffSettingResize');
            return;
        }

        else if( this.isQuitter() == true ) {
            this.event_bus.$emit('OrganizationQuitterSettingResize');
            return;
        }
    }

}
