import VueHoduCommon, { API_METHOD, SHARE_OPTION, CRUD_TYPE, OWNER_TYPE, GROUP_TYPE, EVENT_SUB_TYPE } from '@/mixin/VueHoduCommon';
import { approval_enum } from '@/model/approval';
import { organization_enum } from '@/model/organization';
import { t_event } from '@/model/event';
import moment from 'moment';

/**
 * 푸시알림 noti_type == 'PUSH' 처리
 * @param  {VueHoduCommon} vue
 * @param  {any} data
 */
export async function push_notification_process(vue : VueHoduCommon, data : any) {

    if( data.noti_type != 'PUSH' ) return;

	const noti_uid = data.noti_uid;
    const noti_sub_type = data.noti_sub_type;

    let message = "";
    const target : string = data.team_id && data.team_id > 0 ? '팀' : '그룹';
    
    read_notification(vue, noti_uid);

    switch( noti_sub_type ) {

        /**
         * 일정 공유
         */
        case "EVENT_SHARE":
            message = `${data.body}\n등록하시겠습니까?`;
            vue.hodu_show_dialog("alert", message, ["아니오", "예"], [
                () => {},
                () => { event_share(vue, data);	}
            ]);
            break;

        /**
         * 일정 복사 요청
         */
        case "EVENT_COPY":
            message = `${data.body}\n등록하시겠습니까?`;
            vue.hodu_show_dialog("alert", message, ["아니오", "예"], [
                () => {},
                () => { event_copy(vue, data); }
            ]);
            break;

        /**
         * 일정 이동 요청
         */
        case "EVENT_MOVE":
            message = `${data.body}\n등록하시겠습니까?`;
            vue.hodu_show_dialog("alert", message, ["아니오", "예"], [
                () => {},
                () => { event_move(vue, data); }
            ]);
            break;

        /**
         * 그룹, 팀 일정 공유 && 공유된 일정 수정 && 그룹, 팀 일정 등록 또는 수정
         */
        case "GROUP_EVENT_SHARE":
        case "GROUP_EVENT_PRIVATE_SHARE":
        case "EVENT_MODIFIED":
        case "GROUP_EVENT_CREATE":
        case "GROUP_EVENT_MODIFIED":
        case "CREATE_COMMENT":
            get_and_go_event(vue, data, noti_sub_type);
            break;

        case "CREATE_NOTI_COMMENT":
            get_noti_group_or_team_info(vue, data, noti_sub_type)
            break;

        /**
         * 그룹, 팀 사용자 역할(권한) 변경
         */
        case "GROUP_TEAM_USER_ROLR":
            get_noti_group_or_team_info(vue, data, noti_sub_type);
            break;

        /**
         * 그룹, 팀 가입 승인 요청 && 그룹, 팀 가입 승인
         */
        case "GROUP_TEAM_INFORM":
        case "GROUP_TEAM_APPROV":
            get_noti_group_or_team_info(vue, data, noti_sub_type);
            break;

        /**
         * 그룹, 팀 초대
         */
        case "GROUP_TEAM_INVITE":
            message = `${data.group_name} ${target}에 가입하시겠습니까?`;
            vue.hodu_show_dialog("alert", message, ["아니오", "예"], [
                () => {},
                () => { insert_group_or_team(vue, data); }
            ]);
            break;

        case "GROUP_BOARD_CREATE":
        case "GROUP_BOARD_MODIFIED":
            await go_group_calendar(vue, data);
            vue.hodu_router_push(`/group/${new Date().getTime()}/board`, async() => {
                vue.hodu_router_push(`/group/${new Date().getTime()}/board/${data.board_id}`);
            });
            break;

        case "GROUP_BOARD_COMMENT":
            await go_group_calendar(vue, data);
            vue.hodu_router_push(`/group/${new Date().getTime()}/board`, async() => {
                vue.hodu_router_push(`/group/${new Date().getTime()}/board/${data.board_id}`);
            });

            vue.$store.dispatch("ModalInfo/doSetGroupBoardReplyModalInfo", {
                show_modal : true,
                group_id : data.group_id,
                board_id : data.board_id
            });
            break;

        /**
         * 예약 내용 수정, 상태 변경
         */
        case "APPOINTMENT_CONTENT_CHANGE":
        case "APPOINTMENT_STATUS_CHANGE":
            get_and_go_event(vue, data, noti_sub_type);
            break;

        /**
         * 병원 파일함에 파일 추가
         */
        case "HOSPITAL_ADD_FILE":
            if( vue.scope != OWNER_TYPE.PERSONAL ) { go_personal_calendar(vue); }
            vue.hodu_router_push(`/hospital/file/${data.biz_id}`);
            break;

        /**
         * 전자결재 기안서 생성
         */
        case "APPROVAL_CREATE":
            await go_group_calendar(vue, data);
            vue.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.RECEIVE);
            vue.$store.dispatch("ApprovalInfo/doSetSelectedReceiveTab", approval_enum.APPROVAL_RECEIVE_BOX_TAB.REQUEST);
            vue.hodu_router_push(`/GROUP/${data.group_id}/approval?${new Date().getTime()}`, async() => {
                vue.hodu_router_push(`/GROUP/${data.group_id}/approval/${data.approval_uid}`);
            });
            break;

        /**
         * 전자결재 기안서 수정
         */
        case "APPROVAL_MODIFY":
            await go_group_calendar(vue, data);
            vue.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.RECEIVE);
            vue.$store.dispatch("ApprovalInfo/doSetSelectedReceiveTab", approval_enum.APPROVAL_RECEIVE_BOX_TAB.REQUEST);
            vue.hodu_router_push(`/GROUP/${data.group_id}/approval?${new Date().getTime()}`, async() => {
                vue.hodu_router_push(`/GROUP/${data.group_id}/approval/${data.approval_uid}`);
            });
            break;

        /**
         * 전자결재 기안서 수정 (제외됨)
         */
        case "APPROVAL_MODIFY_EXCEPT":
            message = data.body;
            vue.hodu_show_dialog("alert", message, ["확인"], [async() => {
                await go_group_calendar(vue, data);
                vue.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.RECEIVE);
                vue.$store.dispatch("ApprovalInfo/doSetSelectedReceiveTab", approval_enum.APPROVAL_RECEIVE_BOX_TAB.REQUEST);
                vue.hodu_router_push(`/GROUP/${data.group_id}/approval?${new Date().getTime()}`);
            }]);
            break;

        /**
         * 전자결재 기안서 삭제(취소)
         */
        case "APPROVAL_DELETE":
            
            if( data.scope != null && data.scope.length > 0 ) {
                message = data.body;
                vue.hodu_show_dialog("alert", message, ["확인"], [() => read_notification(vue, noti_uid)]);
            }
            else {
                message = data.body;
                vue.hodu_show_dialog("alert", message, ["확인"], [async() => {
                    await go_group_calendar(vue, data);
                    vue.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.RECEIVE);
                    vue.$store.dispatch("ApprovalInfo/doSetSelectedReceiveTab", approval_enum.APPROVAL_RECEIVE_BOX_TAB.REQUEST);
                    vue.hodu_router_push(`/GROUP/${data.group_id}/approval?${new Date().getTime()}`);
                }]);
            }
            
            break;

        /**
         * 전자결재 기안서 결재 순서
         */
        case "APPROVAL_ORDER":
            await go_group_calendar(vue, data);
            vue.hodu_router_push(`/GROUP/${data.group_id}/approval?${new Date().getTime()}`, async() => {
                vue.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.RECEIVE);
                vue.$store.dispatch("ApprovalInfo/doSetSelectedReceiveTab", approval_enum.APPROVAL_RECEIVE_BOX_TAB.REQUEST);
                vue.hodu_router_push(`/GROUP/${data.group_id}/approval/${data.approval_uid}`);
            });
            break;
            
        /**
         * 전자결재 기안서 반려
         */
        case "APPROVAL_REJECT":
            await go_group_calendar(vue, data);
            vue.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.DRAFT);
            vue.$store.dispatch("ApprovalInfo/doSetSelectedDraftTab", approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL);
            vue.hodu_router_push(`/GROUP/${data.group_id}/approval?${new Date().getTime()}`, async() => {
                vue.hodu_router_push(`/GROUP/${data.group_id}/approval/${data.approval_uid}`);
            });
            break;

        /**
         * 전자결재 기안서 최종 승인
         */
        case "APPROVAL_COMPLETE":
            await go_group_calendar(vue, data);
            vue.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.DRAFT);
            vue.$store.dispatch("ApprovalInfo/doSetSelectedDraftTab", approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL);
            vue.hodu_router_push(`/GROUP/${data.group_id}/approval?${new Date().getTime()}`, async() => {
                vue.hodu_router_push(`/GROUP/${data.group_id}/approval/${data.approval_uid}`);
            });
            break;

        /**
         * 전자결재 휴가원, 출장신청서 최종승인으로 인한 일정 생성
         */
        case "APPROVAL_EVENT_CREATE":

            if( (JSON.parse(data.permitted_user_ids) as number[]).indexOf(vue.user_id) > -1 ) {
                vue.hodu_router_push(`/GROUP/${data.group_id}/approval/${data.approval_uid}`);
            }

            else {
                message = data.body;
                vue.hodu_show_dialog("alert", message, ["확인"], [() => read_notification(vue, noti_uid)]);
            }

            break;

        case "APPROVAL_COMMENT":
            await go_group_calendar(vue, data);
            vue.$store.dispatch("ApprovalInfo/doSetSelectedTab", approval_enum.APPROVAL_BOX_TAB.DRAFT);
            vue.$store.dispatch("ApprovalInfo/doSetSelectedDraftTab", approval_enum.APPROVAL_DRAFT_BOX_TAB.ALL);
            vue.hodu_router_push(`/GROUP/${data.group_id}/approval?${new Date().getTime()}`, async() => {
                vue.hodu_router_push(`/GROUP/${data.group_id}/approval/${data.approval_uid}`);
            });

            vue.$store.dispatch("ModalInfo/doSetApprovalReplyModalInfo", {
                show_modal : true,
                group_id : data.group_id,
                approval_uid : data.approval_uid
            });
            break;

        /**
         * 공유된 일정, 그룹, 팀 일정 삭제
         * 그룹 가입 거절, 그룹 강제탈퇴, 그룹, 팀 삭제
         * 일정 이동 완료, 예약 삭제
         * 지정되지 않은 타입 
         */
        case "EVENT_DELETED":
        case "GROUP_EVENT_DELETED":
        case "GROUP_TEAM_REJECT":
        case "GROUP_TEAM_FORCED_WITHDRAWAL":
        case "GROUP_DELETED":
        case "TEAM_DELETED":
        case "GROUP_BOARD_DELETED":
        case "EVENT_MOVE_COMPLETE": 
        case "APPOINTMENT_DELETE":
        default:
            message = data.body;
            vue.hodu_show_dialog("alert", message, ["확인"], [() => read_notification(vue, noti_uid)]);
            break;
    }
}

/**
 * 알림 읽기 처리
 * @param  {VueHoduCommon} vue
 * @param  {string} noti_uid
 * @returns Promise
 */
export async function read_notification(vue : VueHoduCommon, noti_uid : string) : Promise<void> {
	try {
		const response = await vue.hodu_api_call(`api/v1/notification/insertRead/${noti_uid}`, API_METHOD.PUT, null, false);

		if( !response || !vue.isHttpStatusSuccess(response.status) ) {
			throw new Error("알림 읽기 실패");
		}

	} catch(e) {
		vue.hodu_error_process(e, false, false, true);
	}
}

/**
 * 일정 공유 처리
 * @param  {VueHoduCommon} vue
 * @param  {any} data
 */
async function event_share(vue : VueHoduCommon, data : any) : Promise<void> {
    const event_id : string = data.event_id;
    const scope    : string = data.scope;
    const scope_id : number = data.scope_id;

    try {
        const response = await vue.hodu_api_call(`api/v1/calendars/${scope.toLowerCase()}-${scope_id}/events/${scope}/${scope_id}/${event_id}/share`, API_METHOD.PUT);

        if( !response || !vue.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.event_data ) {
			throw new Error("일정 공유 처리 중 오류 발생");
		}

        const event : t_event = response.data.data.event_data;
        go_event(vue, event);

    } catch(e) {
        vue.hodu_error_process(e, true, false);
    }

}

/**
 * 일정 복사 요청 수락
 * @param  {VueHoduCommon} vue
 * @param  {any} data
 */
async function event_copy(vue : VueHoduCommon, data : any) : Promise<void> {

    const event_id : string = data.event_id;
    const scope    : string = data.scope;
    const scope_id : number = data.scope_id;

    try {
        const response = await vue.hodu_api_call(`api/v1/calendars/${scope.toLowerCase()}-${scope_id}/events/${scope}/${scope_id}/${event_id}/share/copy`, API_METHOD.PUT);

        if( !response || !vue.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.event_data ) {
			throw new Error("일정 복사 처리 중 오류 발생");
		}

        const event : t_event = response.data.data.event_data;
        go_event(vue, event);

    } catch(e) {
        vue.hodu_error_process(e, true, false);
    }

}

/**
 * 일정 이동 요청 수락
 * @param  {VueHoduCommon} vue
 * @param  {any} data
 */
async function event_move(vue : VueHoduCommon, data : any) : Promise<void> {

    const event_id : string = data.event_id;
    const scope    : string = data.scope;
    const scope_id : number = data.scope_id;

    try {
        const response = await vue.hodu_api_call(`api/v1/calendars/${scope.toLowerCase()}-${scope_id}/events/${scope}/${scope_id}/${event_id}/share/move`, API_METHOD.PUT);

        if( !response || !vue.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.event_data ) {
			throw new Error("일정 이동 처리 중 오류 발생");
		}

        const event : t_event = response.data.data.event_data;
        go_event(vue, event);

    } catch(e) {
        vue.hodu_error_process(e, true, false);
    }

}

/**
 * 일정 조회 후 이동
 * @param  {VueHoduCommon} vue
 * @param  {string} calendar_id
 * @param  {string} event_id
 * @param  {string} scope
 * @param  {number} scope_id
 * @param  {string} noti_sub_type?
 */
async function get_and_go_event(vue : VueHoduCommon, data : any, noti_sub_type ?: string) : Promise<void> {

    const calendar_id : string = data.calendar_id;
    const event_id : string = data.event_id;
    const scope : string = data.scope;
    const scope_id : number = data.scope_id;
    
    try {
        const response = await vue.hodu_api_call(`api/v1/calendars/${calendar_id}/events/${scope}/${scope_id}/${event_id}`, API_METHOD.GET);

        if( !response || !vue.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.event_data ) {
			throw new Error("일정 조회 중 오류 발생");
        }
        
        const event : t_event = response.data.data.event_data;
        go_event(vue, event, noti_sub_type);

    } catch(e) {
        vue.hodu_error_process(e, true, false);
    }

}

/**
 * 일정으로 이동
 * @param  {VueHoduCommon} vue
 * @param  {t_event} event
 * @param  {string} noti_sub_type?
 */
async function go_event(vue : VueHoduCommon, event : t_event, noti_sub_type ?: string) : Promise<void> {

    if( event.event_sub_type == EVENT_SUB_TYPE.APPOINTMENT ) {
        go_appointment(vue, event);
        return;
    }

    // EventInfo에 이벤트 등록
    vue.doSetEvent(event);
    vue.doSetEventCrudType(CRUD_TYPE.READ);
    vue.doSetEventShareInfo({
        share_option : SHARE_OPTION.SHARE,
        user_ids : [],
        group_ids : [],
        team_ids : [],
        group_user_ids : [],
        team_user_ids : []
    });

    // 반복일정이라면 첫번째 반복을 보는 취급한다
    if( event.event_data.schedule_date.rrule != null && event.event_data.schedule_date.rrule.length > 0 ) {
        
        vue.$store.dispatch("EventInfo/doSetIsFirstRepeatEvent", true);

        // 음력 반복 일정 일때는 일정의 원래 음력 날짜 store EventInfo에 등록
        if( event.event_data.schedule_date.lunar_yn == true ) {

            vue.$store.dispatch("EventInfo/doSetEventOriginalDate", {
                original_start : await vue.hodu_solar_to_lunar(new Date(moment(event.event_data.schedule_date.start).format('YYYY-MM-DD HH:mm:ss'))),
                original_end : await vue.hodu_solar_to_lunar(new Date(moment(event.event_data.schedule_date.end).format('YYYY-MM-DD HH:mm:ss'))),
            });

        }

        // 그냥 반복 일정 일때는 일정의 원래 날짜 store EventInfo에 등록
        else {
            
            vue.$store.dispatch("EventInfo/doSetEventOriginalDate", {
                original_start : new Date(moment(event.event_data.schedule_date.start).format()),
                original_end : new Date(moment(event.event_data.schedule_date.end).format())
            });

        }
    }

    vue.hodu_router_push(`/event/${event.event_id}`);

    // 타입에 따른 추가 처리
    switch( noti_sub_type ) {
        case "CREATE_COMMENT":
            vue.$store.dispatch("ModalInfo/doSetShowEventReply", true);
            break;
    }
}

/**
 * 예약으로 이동
 * @param  {VueHoduCommon} vue
 * @param  {t_event} event
 * @returns Promise
 */
async function go_appointment(vue : VueHoduCommon, event : t_event) : Promise<void> {
    
    if( vue.scope != OWNER_TYPE.PERSONAL ) { go_personal_calendar(vue); }
    
    vue.$store.dispatch("HoduDocInfo/doSetAppointmentDetailInfo", {
        event : event,
        is_patient : true,
    });
    
    vue.hodu_router_push(`/hospital/${new Date()}/appointment/${event.event_id}`);
}

/**
 * scope, scope_id로 그룹 또는 팀 정보 가져오기
 * @param  {VueHoduCommon} vue
 * @param  {any} data
 * @param  {string} noti_sub_type
 */
async function get_noti_group_or_team_info(vue : VueHoduCommon, data : any, noti_sub_type : string) : Promise<void> {
    
    let scope : string = data.scope;
    let scope_id : number = data.scope_id;

    if( !scope ) {
        scope = data.team_id > 0 ? OWNER_TYPE.TEAM : data.group_id > 0 ? OWNER_TYPE.GROUP : OWNER_TYPE.PERSONAL;
    }

    if( !scope_id ) {
        scope_id = data.team_id > 0 ? data.team_id : data.group_id > 0 ? data.group_id : 0;
    }

    try {
        const response = await vue.hodu_api_call(`api/v1/groupsweb/notification/${scope}/${scope_id}/info`, API_METHOD.GET);

        if( !response || !vue.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.group_or_team_info ) {
			throw new Error("그룹, 팀 정보 조회 중 오류 발생");
        }

        const group_or_team_info : any = response.data.data.group_or_team_info;

        vue.doGroupId(group_or_team_info.group_id);
        vue.doTeamId(group_or_team_info.team_id);
        vue.doGroupTeamOption({
            group_team_name : group_or_team_info.group_team_name,
            group_team_color : group_or_team_info.group_team_color,
            group_team_image : group_or_team_info.group_team_image,
            group_team_descript : group_or_team_info.group_team_descript,
            biz_id : group_or_team_info.biz_id,            
            noti_uid : noti_sub_type == 'CREATE_NOTI_COMMENT' ? data.event_id : ""
        });

        switch( noti_sub_type ) {
            case "GROUP_TEAM_USER_ROLR":

                // 전자결재 또는 근태현황 중 하나라도 사용시
                if( (vue.isEnableGroupFeature(group_or_team_info.group_id, 'attendance') || vue.isEnableGroupFeature(group_or_team_info.group_id, 'approval')) ) {
                    await go_group_calendar(vue, data);
                    vue.$store.dispatch("OrganizationInfo/doSetOrganizationMemberSelectedTab", organization_enum.ORGANIZATION_SETTING_TAB.MEMBER);
                    vue.hodu_router_push(`/${scope}/${scope_id}/organization/member/setting?${new Date().getTime()}`);
                }
                else {
                    vue.hodu_router_push(`/group/${new Date().getTime()}/member`);
                }
                
                break;

            case "GROUP_TEAM_INFORM":
                if( data.is_hodu_home_resident == true ) {
                    await go_group_calendar(vue, data);
                    vue.hodu_router_push(`home/resident/join?${new Date().getTime()}`);
                    break;
                }
                vue.hodu_router_push(`/group/${new Date().getTime()}/approve`);
                break;

            case "GROUP_TEAM_APPROV":
                vue.doGroupStatisticsStartDate("");
                vue.hodu_router_push(`/group/${new Date().getTime()}/detail`);
                break;

            case "CREATE_NOTI_COMMENT":
                vue.hodu_router_push(`/group/${new Date().getTime()}/notice_detail`);

                vue.$store.dispatch("ModalInfo/doSetGroupNoticeReplyModalInfo", {
                    show_modal : true,
                    noti_uid : data.event_id
                });
                break;
        }

    } catch(e) {
        vue.hodu_error_process(e, true, false);
    }
}

/**
 * 개인 달력으로 이동
 * @param  {VueHoduCommon} vue
 */
async function go_personal_calendar(vue : VueHoduCommon) : Promise<void> {
    vue.doSetGroupMenuClose(false);
    vue.doSetCalendarId(`personal-${vue.user_id}`);
    vue.doSetScope(OWNER_TYPE.PERSONAL);  
    vue.doSetScopeGroupId(0);  
    vue.doSetScopeTeamId(0);  
    vue.doSetScopeGroupTeamOption({
        group_team_name : '',
        group_team_color : '#FFFFFF',
        group_team_image : '',
        group_team_descript : '',
        biz_id : '',
        biz_type : ''
    });

    await vue.hodu_loading_timer_exit();
    await vue.hodu_loading();

    setTimeout(() => {
        // @ts-ignore
        $('.left_inbox02').mCustomScrollbar('destroy');
        window['left_inbox02_mCustomScrollbar']();
    }, 100);

    if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
}

/**
 * 그룹 달력으로 이동
 * @param  {VueHoduCommon} vue
 * @param  {any} data
 */
async function go_group_calendar(vue : VueHoduCommon, data : any) : Promise<void> {

    const targets = vue.group_info_list.filter(item => item.group_id == data.group_id);
    if( targets.length < 1 ) return;

    const target = targets[0];

    if( $('#bt_calContr').hasClass('on') == false ) {
        $('#container').find('#left_area').find('.left_inbox').addClass('on');
        $('.ui-datepicker-calendar-wrap').hide();
        $('#bt_calContr').addClass('on');

        const left_area_height : number | undefined = $('#left_area').outerHeight();
        const left_inbox_height : number | undefined = $('.left_inbox').outerHeight();

        const left_area_height_final : number = left_area_height == null ? 0 : left_area_height;
        const left_inbox_height_final : number = left_inbox_height == null ? 0 : left_inbox_height;

        let heightSize : number = left_area_height_final - left_inbox_height_final;
        $('.left_inbox02').height(heightSize+"px");
    }

    await vue.doSetGroupMenuClose(true);
    await vue.doSetMessageMenuClose(true);
    await vue.doSetWorkMenuClose(true);
    await vue.doSetCommunityMenuClose(true);
    await vue.doSetResidentMenuClose(true);

    await vue.doSetCalendarId(`group-${data.group_id}`);
    await vue.doSetScope(OWNER_TYPE.GROUP);  
    await vue.doSetScopeGroupId(data.group_id);  
    await vue.doSetScopeTeamId(0);  

    // 조회
    await vue.doSetScopeGroupTeamOption({
        group_team_name : target.group_name,
        group_team_color : target.color,
        group_team_image : `profile/group/${Math.floor(target.group_id / 10000)}/${target.group_id}.jpg`,
        group_team_descript : '',
        biz_id : target.biz_id,
        biz_type : target.biz_type
    });

    await vue.hodu_loading_timer_exit();
    await vue.hodu_loading();

    setTimeout(() => {
        // @ts-ignore
        $('.left_inbox02').mCustomScrollbar('destroy');
        window['left_inbox02_mCustomScrollbar']();
    }, 100);

    if( window["setFilterCheckColor"] != null ) { window["setFilterCheckColor"](); }
}

/**
 * 그룹, 팀에 가입
 * @param  {VueHoduCommon} vue
 * @param  {any} data
 * @returns Promise
 */
async function insert_group_or_team(vue : VueHoduCommon, data : any) : Promise<void> {
    
    const group_id : number = data.group_id;
    const team_id : number = data.team_id;
    const scope : string = team_id && team_id > 0 ? OWNER_TYPE.TEAM : group_id && group_id > 0 ? OWNER_TYPE.GROUP : OWNER_TYPE.PERSONAL;

    // 아파트 초대
    if( data.biz_type == 'BIZH' ) {

        // 가입된 그룹인지 체크 후 가입 된거면 그냥 이동
        Promise.all([vue.get_group_role_service()]).then(() => {

            if( data.group_id == null || data.group_id < 1 ) {
                return;
            }

            let group_joined : boolean = false;
            for( let group of vue.all_group_data ) {
                if( group.group_id == data.group_id ) {
                    group_joined = true;
                }
            }

            if( group_joined == true ) {
                vue.doGroupId(data.group_id);
                vue.doTeamId(0);
                vue.doGroupStatisticsStartDate("");
                vue.hodu_router_push(`/group/${new Date().getTime()}/detail`);
                return;
            }

            vue.$store.dispatch("ModalInfo/doSetHomeResidentJoinModalInfo", {
                show_modal : true,
                group_id : data.group_id,
                is_invite : true
            });
        });
        return;
    }

    // 그룹, 팀에 가입처리 + 개인 달력의 group_detail로 이동!
    if( scope == OWNER_TYPE.GROUP ) {
        // 가입된 그룹인지 체크 후 가입 된거면 그냥 이동
        Promise.all([vue.get_group_role_service()]).then(() => {

            if( data.group_id == null || data.group_id < 1 ) {
                return;
            }

            let group_joined : boolean = false;
            for( let group of vue.all_group_data ) {
                if( group.group_id == data.group_id ) {
                    group_joined = true;
                }
            }

            if( group_joined == true ) {
                vue.doGroupId(data.group_id);
                vue.doTeamId(0);
                vue.doGroupStatisticsStartDate("");
                vue.hodu_router_push(`/group/${new Date().getTime()}/detail`);
                return;
            }

            groupMemberInsert(vue, (group_id ? group_id : 0), false);
        });
    } 
    
    else if( scope == OWNER_TYPE.TEAM ) {
        
        // 가입된 그룹인지 체크 후 가입 안되어있으면 그룹 가입 후 팀 가입
        Promise.all([vue.get_group_role_service()]).then(() => {
            let group_joined : boolean = false;
            for( let group of vue.all_group_data ) {
                if( group.group_id == group_id ) {
                    group_joined = true;
                }
            }

            // 가입 된 그룹이라면 바로 팀 가입
            if( group_joined == true ) {
                teamMemberInsert(vue, (group_id ? group_id : 0), (team_id ? team_id : 0), false);
            }

            // 가입 안 된 그룹이라면 그룹 가입 후 팀 가입
            else {
                Promise.all([groupMemberInsert(vue, (group_id ? group_id : 0), false)]).then(() => {
                    teamMemberInsert(vue, (group_id ? group_id : 0), (team_id ? team_id : 0), false);
                })
            }
        })

    }
    
}

/**
 * 그룹 가입
 * @param  {VueHoduCommon} vue
 * @param  {number} group_id
 * @param  {boolean} is_appr_required
 */
async function groupMemberInsert(vue : VueHoduCommon, group_id : number, is_appr_required : boolean) : Promise<void> {
    
    try {
        const response = await vue.hodu_api_call(`api/v1/groups/groupMemberInsert/${group_id}`, API_METHOD.POST, { "is_appr_required" : is_appr_required })
            
        if( !response || !vue.isHttpStatusSuccess(response.status) ) {
			throw new Error("그룹 가입 중 오류 발생");
        }

        // 개인 달력이 아니라면 개인 달력으로 이동
        if( vue.scope != OWNER_TYPE.PERSONAL ) { go_personal_calendar(vue); }

        vue.doGroupId(group_id);
        vue.doTeamId(0);
        vue.doGroupStatisticsStartDate("");
        vue.hodu_router_push(`/group/${new Date().getTime()}/detail`);

    } catch(e) {
        vue.hodu_error_process(e, true, false);
    }

}

/**
 * 팀 가입
 * @param  {VueHoduCommon} vue
 * @param  {number} group_id
 * @param  {number} team_id
 * @param  {boolean} is_appr_required
 */
async function teamMemberInsert(vue : VueHoduCommon, group_id : number, team_id : number, is_appr_required : boolean) : Promise<void> {
    
    try {

        const response = await vue.hodu_api_call(`api/v1/teams/teamMemberInsert/${group_id}/${team_id}`, API_METHOD.POST, { "is_appr_required" : is_appr_required });

        if( !response || !vue.isHttpStatusSuccess(response.status) ) {
			throw new Error("팀 가입 중 오류 발생");
        }

        // 개인 달력이 아니라면 개인 달력으로 이동
        if( vue.scope != OWNER_TYPE.PERSONAL ) { go_personal_calendar(vue); }

        vue.doGroupId(group_id);
        vue.doTeamId(team_id);
        vue.doGroupStatisticsStartDate("");
        vue.hodu_router_push(`/group/${new Date().getTime()}/detail`);

    } catch(e) {
        vue.hodu_error_process(e, true, false);
    }

}